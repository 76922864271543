import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function Cities() {
	const { t, i18n } = useTranslation();
	const [isLoading, isLoadingSet] = useState(false);
	const [addModal, addModalSet] = useState(false);
	const [editModal, editModalSet] = useState(false);
	const [editModalItem, editModalItemSet] = useState(false);
	const [deleteModal, deleteModalSet] = useState(false);
	const [cities, citiesSet] = useState([]);
	const [nameAr, nameArSet] = useState("");
	const [nameEn, nameEnSet] = useState("");
	const [areas, areasSet] = useState([]);
	const [area, areaSet] = useState("");
	const [isSubmitting, isSubmittingSet] = useState(false);

	function handleClose() {
		addModalSet(false);
		editModalSet(false);
		editModalItemSet(false);
		deleteModalSet(false);
		nameArSet("");
		nameEnSet("");
		areaSet("");
	}

	function handleAdd(e) {
		e.preventDefault();
		isSubmittingSet(true);
		axios
			.post("/cities", {
				name_en: nameEn,
				name_ar: nameAr,
				area_id: area,
			})
			.then((result) => {
				isSubmittingSet(false);
				handleClose();
				isLoadingSet(true);
				citiesSet([]);
				axios
					.get("/cities", {
						headers: {
							lang: i18n.language,
						},
					})
					.then((result) => {
						isLoadingSet(false);
						citiesSet(result.data);
					});
			})
			.catch((err) => console.log(err.response));
	}

	function handleEdit(e) {
		e.preventDefault();
		isSubmittingSet(true);
		axios
			.put(`/cities/${editModal.id}`, {
				name_en: nameEn,
				name_ar: nameAr,
				area_id: area,
			})
			.then((result) => {
				isSubmittingSet(false);
				handleClose();
				isLoadingSet(true);
				citiesSet([]);
				axios
					.get("/cities", {
						headers: {
							lang: i18n.language,
						},
					})
					.then((result) => {
						isLoadingSet(false);
						citiesSet(result.data);
					});
			})
			.catch((err) => console.log(err.response));
	}

	function handleDelete() {
		isSubmittingSet(true);
		axios.delete(`/cities/${deleteModal.id}`).then(() => {
			isSubmittingSet(false);
			handleClose();
			isLoadingSet(true);
			citiesSet([]);
			axios
				.get("/cities", {
					headers: {
						lang: i18n.language,
					},
				})
				.then((result) => {
					isLoadingSet(false);
					citiesSet(result.data);
				});
		});
	}

	useEffect(() => {
		isLoadingSet(true);
		axios
			.get("/areas", {
				headers: {
					lang: i18n.language,
				},
			})
			.then((result) => {
				isLoadingSet(false);
				areasSet(result.data);
			});
		axios
			.get("/cities", {
				headers: {
					lang: i18n.language,
				},
			})
			.then((result) => {
				isLoadingSet(false);
				citiesSet(result.data);
			});
	}, []);

	useEffect(() => {
		if (editModal) {
			nameArSet(editModal.name_ar);
			nameEnSet(editModal.name_en);
			areaSet(editModal.area_id);
		}
	}, [editModal]);

	return (
		<div>
			<h1 className="display-4 mb-5 text-left">{t("cities")}</h1>
			<div className="d-flex align-items-center justifiy-content-between mb-5">
				<button
					className="btn btn-success"
					onClick={() => addModalSet(true)}
				>
					{t("addNewCity")}
				</button>
			</div>

			<table className="table table-hover text-left">
				<thead>
					<tr>
						<th scope="col">{t("name")}</th>

						<th scope="col" style={{ width: "160px" }}>
							{t("options")}
						</th>
					</tr>
				</thead>
				<tbody>
					{cities.map((item) => (
						<tr key={item.id}>
							<td scope="row">{item["name_" + i18n.language]}</td>

							<td>
								<button
									className="btn btn-primary ml-auto mr-1"
									onClick={() => editModalSet(item)}
								>
									{t("edit")}
								</button>
								<button
									className="btn btn-danger ml-auto"
									onClick={() => deleteModalSet(item)}
								>
									{t("delete")}
								</button>
							</td>
						</tr>
					))}
					{!isLoading && cities.length < 1 ? (
						<tr>
							<td colSpan="6">
								<p className="text-center mt-3 text-muted">
									{t("noAreas")}
								</p>
							</td>
						</tr>
					) : (
						""
					)}
				</tbody>
			</table>
			{isLoading && (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-primary"
						role="status"
					></div>
				</div>
			)}

			{/** Add Modal */}

			<Modal
				size="lg"
				show={addModal}
				onHide={handleClose}
				aria-labelledby="add-branch-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleAdd}>
					<Modal.Header>
						<Modal.Title id="add-branch-modal">
							{t("addNewCity")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<p className="text-left"> {t("area")} </p>

							<select
								className="form-control"
								required
								value={area}
								onChange={(e) => areaSet(e.target.value)}
							>
								<option></option>
								{areas.map((item) => (
									<option key={item.id} value={item.id}>
										{item["name_" + i18n.language]}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<p className="text-left"> اسم المدينة </p>
							<input
								className="form-control"
								required
								value={nameAr}
								onChange={(e) => nameArSet(e.target.value)}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> City name </p>
							<input
								className="form-control"
								required
								value={nameEn}
								onChange={(e) => nameEnSet(e.target.value)}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>
						<button
							className="btn btn-success"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("add")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Edit Modal */}

			<Modal
				size="lg"
				show={editModal}
				onHide={handleClose}
				aria-labelledby="edit-branch-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleEdit}>
					<Modal.Header>
						<Modal.Title id="edit-branch-modal">
							{t("editCity")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<p className="text-left"> {t("area")} </p>

							<select
								className="form-control"
								required
								value={area}
								onChange={(e) => areaSet(e.target.value)}
							>
								<option value=""></option>
								{areas.map((item) => (
									<option key={item.id} value={item.id}>
										{item["name_" + i18n.language]}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<p className="text-left"> اسم المدينة </p>
							<input
								className="form-control"
								required
								value={nameAr}
								onChange={(e) => nameArSet(e.target.value)}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> City name </p>
							<input
								className="form-control"
								required
								value={nameEn}
								onChange={(e) => nameEnSet(e.target.value)}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>
						<button
							className="btn btn-primary"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("edit")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Delete Modal */}

			<Modal
				size="lg"
				show={deleteModal}
				onHide={handleClose}
				aria-labelledby="delete-category-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<Modal.Header>
					<Modal.Title id="delete-category-modal">
						{t("delete")} {deleteModal?.name}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="text-left">{t("deleteCityModal")}</p>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn" onClick={handleClose}>
						{t("cancel")}
					</button>
					<button
						className="btn btn-danger"
						onClick={handleDelete}
						disabled={isSubmitting}
					>
						{isSubmitting && (
							<span
								className="spinner-border spinner-border-sm mr-2"
								role="status"
							></span>
						)}
						{t("delete")}
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default Cities;
