import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Switch, Route, Redirect } from "react-router-dom";
import Orders from "./Orders";
import Colors from "./Colors";
import Visits from "./Visits";
import Categories from "./Categories";
import Products from "./Products";
import ProductAdd from "./ProductAdd";
import ProductShow from "./ProductShow";
import ProductEdit from "./ProductEdit";
import Branches from "./Branches";
import Messages from "./Messages";
import Catalogs from "./Catalogs";
import Sizes from "./Sizes";
import Sheen from "./Sheen";
import Areas from "./Areas";
import Cities from "./Cities";
import Users from "./Users";
import Settings from "./Settings";
import Newsletter from "./Newsletter";

function AppContainer() {
	return (
		<div>
			<Header />
			<div className="container mt-5">
				<div className="row mt-5">
					<Sidebar />
					<div className="col-lg-9 mb-5">
						<Switch>
							<Route path="/" exact>
								<Redirect to="/orders" />
							</Route>
							<Route path="/orders" component={Orders} />
							<Route path="/colors" component={Colors} />
							<Route path="/visits" component={Visits} />
							<Route path="/categories" component={Categories} />
							<Route path="/catalogs" component={Catalogs} />
							<Route path="/areas" component={Areas} />
							<Route path="/cities" component={Cities} />
							<Route path="/users" component={Users} />
							<Route path="/newsletter" component={Newsletter} />
							<Route path="/settings" component={Settings} />
							<Route
								path="/products/add"
								component={ProductAdd}
								exact
							/>
							<Route
								path="/products/:productId/edit"
								component={ProductEdit}
								exact
							/>
							<Route
								path="/products/:productId"
								component={ProductShow}
								exact
							/>
							<Route
								path="/products"
								component={Products}
								exact
							/>
							<Route
								path="/branches"
								component={Branches}
								exact
							/>
							<Route
								path="/messages"
								component={Messages}
								exact
							/>
							<Route path="/sizes" component={Sizes} exact />
							<Route path="/sheen" component={Sheen} exact />
						</Switch>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AppContainer;
