import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

function Categories() {
	const { t, i18n } = useTranslation();
	const [categories, categoriesSet] = useState([]);
	const [isLoading, isLoadingSet] = useState(true);
	const [isSubmitting, isSubmittingSet] = useState(false);
	const [addCategory, addCategorySet] = useState(false);
	const [editCategory, editCategorySet] = useState(false);
	const [editCategoryItem, editCategoryItemSet] = useState(false);
	const [deleteCategory, deleteCategorySet] = useState(false);
	const [categoryNameEn, categoryNameEnSet] = useState("");
	const [categoryNameAr, categoryNameArSet] = useState("");

	const [categoryImageUrl, categoryImageUrlSet] = useState("");
	const [categoryImage, categoryImageSet] = useState(false);

	function handleAdd(e) {
		e.preventDefault();
		isSubmittingSet(true);
		let formData = new FormData();
		formData.append("name_en", categoryNameEn);
		formData.append("name_ar", categoryNameAr);

		formData.append("image", categoryImage);
		axios
			.post("/categories", formData)
			.then(() => {
				handleClose();
				isSubmittingSet(false);
				axios
					.get("/categories", {
						headers: {
							lang: i18n.language,
						},
					})
					.then((result) => {
						categoriesSet(result.data.data);
					});
			})
			.catch((err) => console.log(err.response));
	}

	function handleEdit(e) {
		e.preventDefault();
		isSubmittingSet(true);
		let formData = new FormData();
		if (categoryImage) {
			formData.append("name_en", categoryNameEn);
			formData.append("name_ar", categoryNameAr);

			formData.append("image", categoryImage);
			axios
				.post(`/categories/${editCategory.id}/update`, formData)
				.then((result) => {
					handleClose();
					isSubmittingSet(false);
					axios
						.get("/categories", {
							headers: {
								lang: i18n.language,
							},
						})
						.then((result) => {
							categoriesSet(result.data.data);
						});
				});
		} else {
			formData.append("name_en", categoryNameEn);
			formData.append("name_ar", categoryNameAr);

			axios
				.post(`/categories/${editCategory.id}/update`, formData)
				.then((result) => {
					handleClose();
					isSubmittingSet(false);
					axios
						.get("/categories", {
							headers: {
								lang: i18n.language,
							},
						})
						.then((result) => {
							categoriesSet(result.data.data);
						});
				});
		}
	}

	function handleClose() {
		deleteCategorySet(false);
		addCategorySet(false);
		editCategorySet(false);
		editCategoryItemSet(false);
		isSubmittingSet(false);
		editCategoryItemSet(false);
		categoryNameArSet("");
		categoryNameEnSet("");

		categoryImageSet(false);
		categoryImageUrlSet("");
	}

	function handleDelete() {
		axios.delete(`/categories/${deleteCategory.id}`).then(() => {
			handleClose();
			axios
				.get("/categories", {
					headers: {
						lang: i18n.language,
					},
				})
				.then((result) => {
					categoriesSet(result.data.data);
				});
		});
	}

	useEffect(() => {
		isLoadingSet(true);
		categoriesSet([]);
		axios
			.get("/categories", {
				headers: {
					lang: i18n.language,
				},
			})
			.then((result) => {
				isLoadingSet(false);
				categoriesSet(result.data.data);
			});
	}, [i18n.language]);

	useEffect(() => {
		if (editCategory) {
			axios
				.get(`/categories/${editCategory.id}/all`)
				.then((result) => {
					editCategoryItemSet(true);
					categoryNameArSet(result.data.data.name_ar);
					categoryNameEnSet(result.data.data.name_en);
					categoryImageUrlSet(result.data.data.image);
				})
				.catch((err) => console.log(err.response));
		}
	}, [editCategory]);

	return (
		<div>
			<h1 className="display-4 mb-5 text-left">{t("categories")}</h1>
			<div className="d-flex align-items-center justifiy-content-between mb-5">
				<button
					className="btn btn-success"
					onClick={() => addCategorySet(true)}
				>
					{t("addNewCategory")}
				</button>
			</div>

			<table className="table table-hover text-left">
				<thead>
					<tr>
						<th scope="col">{t("name")}</th>

						<th scope="col" style={{ width: "160px" }}>
							{t("options")}
						</th>
					</tr>
				</thead>
				<tbody>
					{categories.map((category) => (
						<tr key={category.id}>
							<th scope="row">{category.name}</th>

							<td>
								<button
									className="btn btn-primary ml-auto mr-1"
									onClick={() => editCategorySet(category)}
								>
									{t("edit")}
								</button>
								<button
									className="btn btn-danger ml-auto"
									onClick={() => deleteCategorySet(category)}
								>
									{t("delete")}
								</button>
							</td>
						</tr>
					))}
					{!isLoading && categories.length < 1 ? (
						<tr>
							<td colSpan="6">
								<p className="text-center mt-3 text-muted">
									{t("noCategories")}
								</p>
							</td>
						</tr>
					) : (
						""
					)}
				</tbody>
			</table>
			{isLoading && (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-primary"
						role="status"
					></div>
				</div>
			)}

			{/** Add Modal */}

			<Modal
				size="lg"
				show={addCategory}
				onHide={handleClose}
				aria-labelledby="add-category-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleAdd}>
					<Modal.Header>
						<Modal.Title id="add-category-modal">
							{t("addNewCategory")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<p className="text-left"> اسم الفئة </p>
							<input
								className="form-control"
								required
								value={categoryNameAr}
								onChange={(e) =>
									categoryNameArSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Category name </p>
							<input
								className="form-control"
								required
								value={categoryNameEn}
								onChange={(e) =>
									categoryNameEnSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> {t("image")}</p>
							<input
								className="form-control p-1"
								required
								type="file"
								accept="image/*"
								onChange={(e) =>
									categoryImageSet(e.target.files[0])
								}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>
						<button
							className="btn btn-success"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("add")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Edit Modal */}

			<Modal
				size="lg"
				show={editCategory}
				onHide={handleClose}
				animation={false}
				aria-labelledby="add-category-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleEdit}>
					<Modal.Header>
						<Modal.Title id="add-category-modal">
							{t("editCategory")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{editCategoryItem ? (
							<>
								<div className="form-group">
									<p className="text-left"> اسم الفئة </p>
									<input
										className="form-control"
										required
										value={categoryNameAr}
										onChange={(e) =>
											categoryNameArSet(e.target.value)
										}
									/>
								</div>

								<div className="form-group">
									<p className="text-left"> Category name </p>
									<input
										className="form-control"
										required
										value={categoryNameEn}
										onChange={(e) =>
											categoryNameEnSet(e.target.value)
										}
									/>
								</div>

								<img
									src={categoryImageUrl}
									className="ml-auto mr-auto d-block"
									style={{ width: "250px" }}
									alt=""
								/>
								<div className="form-group">
									<p className="text-left"> {t("image")}</p>
									<input
										className="form-control p-1"
										type="file"
										accept="image/*"
										onChange={(e) => {
											categoryImageSet(e.target.files[0]);
											categoryImageUrlSet(
												URL.createObjectURL(
													e.target.files[0]
												)
											);
										}}
									/>
								</div>
							</>
						) : (
							<div className="d-flex justify-content-center mt-5 mb-5">
								<div
									className="spinner-border text-primary"
									role="status"
								></div>
							</div>
						)}
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>
						<button
							className="btn btn-primary"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("edit")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Delete Modal */}

			<Modal
				size="lg"
				show={deleteCategory}
				onHide={handleClose}
				aria-labelledby="delete-category-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<Modal.Header>
					<Modal.Title id="delete-category-modal">
						{t("delete")} {deleteCategory?.name}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="text-left">{t("deleteCategory")}</p>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn" onClick={handleClose}>
						{t("cancel")}
					</button>
					<button className="btn btn-danger" onClick={handleDelete}>
						{t("delete")}
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default Categories;
