import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

function Products() {
	const { t, i18n } = useTranslation();
	const [products, productsSet] = useState([]);
	const [isLoading, isLoadingSet] = useState(true);

	const [pages, pagesSet] = useState(0);
	const [currentPage, currentPageSet] = useState(1);

	useEffect(() => {
		productsSet([]);
		isLoadingSet(true);
		axios
			.get("/products", {
				headers: {
					lang: i18n.language,
				},
				params: {
					page: currentPage,
				},
			})
			.then((result) => {
				isLoadingSet(false);
				pagesSet(result.data.meta.last_page);
				productsSet(result.data.data);
			})
			.catch((err) => console.log(err.response));
	}, [i18n.language, currentPage]);

	return (
		<div>
			<h1 className="display-4 mb-5 text-left">{t("products")}</h1>
			<div className="d-flex align-items-center justifiy-content-between mb-5">
				<Link className="btn btn-success" to="/products/add">
					{t("addNewProduct")}
				</Link>
			</div>

			<table className="table table-hover text-left">
				<thead>
					<tr>
						<th scope="col">{t("name")}</th>
						<th scope="col">{t("category")}</th>
						<th scope="col">{t("price")}</th>

						<th scope="col" style={{ width: "150px" }}>
							{t("options")}
						</th>
					</tr>
				</thead>
				<tbody>
					{products?.map((product) => (
						<tr key={product.id}>
							<td scope="row">{product.name}</td>
							<td>{product.category.name}</td>
							<td>
								{product.price} {t("sar")}
							</td>

							<td>
								<Link
									className="btn btn-primary ml-auto"
									to={`/products/${product.id}`}
								>
									{t("details")}
								</Link>
							</td>
						</tr>
					))}
					{!isLoading && products.length < 1 ? (
						<tr>
							<td colSpan="6">
								<p className="text-center mt-3 text-muted">
									{t("noProducts")}
								</p>
							</td>
						</tr>
					) : (
						""
					)}
				</tbody>
			</table>
			{isLoading && (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-primary"
						role="status"
					></div>
				</div>
			)}

			<div className="d-flex justify-content-center">
				<Pagination
					count={pages}
					color="secondary"
					shape="rounded"
					page={currentPage}
					onChange={(e, value) => currentPageSet(value)}
					hidePrevButton
					hideNextButton
				/>
			</div>
		</div>
	);
}

export default Products;
