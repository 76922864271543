import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

function Branches() {
	const { t, i18n } = useTranslation();
	const [isLoading, isLoadingSet] = useState(false);
	const [addBranch, addBranchSet] = useState(false);
	const [editBranch, editBranchSet] = useState(false);
	const [editBranchItem, editBranchItemSet] = useState(false);
	const [deleteBranch, deleteBranchSet] = useState(false);
	const [branches, branchesSet] = useState([]);
	const [areas, areasSet] = useState([]);
	const [cities, citiesSet] = useState([]);
	const [filteredCities, filteredCitiesSet] = useState([]);
	const [branchNameAr, branchNameArSet] = useState("");
	const [branchNameEn, branchNameEnSet] = useState("");
	const [branchAddressAr, branchAddressArSet] = useState("");
	const [branchPhone, branchPhoneSet] = useState("");
	const [branchAddressEn, branchAddressEnSet] = useState("");
	const [branchLongitude, branchLongitudeSet] = useState("");
	const [branchLatitude, branchLatitudeSet] = useState("");
	const [branchCity, branchCitySet] = useState("");
	const [branchArea, branchAreaSet] = useState("");
	const [isSubmitting, isSubmittingSet] = useState(false);

	function handleClose() {
		addBranchSet(false);
		editBranchSet(false);
		editBranchItemSet(false);
		deleteBranchSet(false);
		branchNameArSet("");
		branchNameEnSet("");
		branchAddressArSet("");
		branchAddressEnSet("");
		branchLongitudeSet("");
		branchLatitudeSet("");
		branchAreaSet("");
		branchCitySet("");
		branchPhoneSet("");
	}

	function handleAdd(e) {
		e.preventDefault();
		isSubmittingSet(true);
		axios
			.post("/branches", {
				name_en: branchNameEn,
				name_ar: branchNameAr,
				address_en: branchAddressEn,
				address_ar: branchAddressAr,
				longitude: branchLongitude,
				latitude: branchLatitude,
				phone: branchPhone,
				city_id: branchCity,
			})
			.then((result) => {
				console.log(result);
				isSubmittingSet(false);
				handleClose();
				isLoadingSet(true);
				branchesSet([]);
				axios
					.get("/branches", {
						headers: {
							lang: i18n.language,
						},
					})
					.then((result) => {
						isLoadingSet(false);
						branchesSet(result.data.data);
					});
			})
			.catch((err) => console.log(err.response));
	}

	function handleEdit(e) {
		e.preventDefault();
		isSubmittingSet(true);
		axios
			.put(`/branches/${editBranch.id}`, {
				name_en: branchNameEn,
				name_ar: branchNameAr,
				address_en: branchAddressEn,
				address_ar: branchAddressAr,
				longitude: branchLongitude,
				latitude: branchLatitude,
				phone: branchPhone,
				city_id: branchCity,
			})
			.then((result) => {
				isSubmittingSet(false);
				handleClose();
				isLoadingSet(true);
				branchesSet([]);
				axios
					.get("/branches", {
						headers: {
							lang: i18n.language,
						},
					})
					.then((result) => {
						isLoadingSet(false);
						branchesSet(result.data.data);
					});
			})
			.catch((err) => console.log(err.response));
	}

	function handleDelete() {
		isSubmittingSet(true);
		axios.delete(`/branches/${deleteBranch.id}`).then(() => {
			isSubmittingSet(false);
			handleClose();
			isLoadingSet(true);
			branchesSet([]);
			axios
				.get("/branches", {
					headers: {
						lang: i18n.language,
					},
				})
				.then((result) => {
					isLoadingSet(false);
					branchesSet(result.data.data);
				});
		});
	}

	useEffect(() => {
		isLoadingSet(true);
		branchesSet([]);
		axios
			.get("/branches", {
				headers: {
					lang: i18n.language,
				},
			})
			.then((result) => {
				isLoadingSet(false);
				branchesSet(result.data.data);
			});
	}, [i18n.language]);

	useEffect(() => {
		if (branchArea) {
			filteredCitiesSet(cities.filter((i) => i.area_id == branchArea));
		} else {
			filteredCitiesSet(cities);
		}
	}, [branchArea]);

	useEffect(() => {
		axios
			.get("/areas", {
				headers: {
					lang: i18n.language,
				},
			})
			.then((result) => {
				isLoadingSet(false);
				areasSet(result.data);
			});
		axios
			.get("/cities", {
				headers: {
					lang: i18n.language,
				},
			})
			.then((result) => {
				isLoadingSet(false);
				citiesSet(result.data);
				filteredCitiesSet(result.data);
			});
	}, []);

	useEffect(() => {
		if (editBranch) {
			editBranchItemSet(true);
			axios.get(`/branches/${editBranch.id}`).then((result) => {
				editBranchItemSet(false);
				console.log(result);
				branchNameArSet(result.data.name_ar);
				branchNameEnSet(result.data.name_en);
				branchAddressArSet(result.data.address_ar);
				branchAddressEnSet(result.data.address_en);
				branchPhoneSet(result.data.phone);
				branchLongitudeSet(result.data.longitude);
				branchLatitudeSet(result.data.latitude);
				branchCitySet(result.data.city_id);
			});
		}
	}, [editBranch]);

	return (
		<div>
			<h1 className="display-4 mb-5 text-left">{t("branches")}</h1>
			<div className="d-flex align-items-center justifiy-content-between mb-5">
				<button
					className="btn btn-success"
					onClick={() => addBranchSet(true)}
				>
					{t("addNewBranch")}
				</button>
			</div>

			<table className="table table-hover text-left">
				<thead>
					<tr>
						<th scope="col">{t("name")}</th>

						<th scope="col" style={{ width: "160px" }}>
							{t("options")}
						</th>
					</tr>
				</thead>
				<tbody>
					{branches.map((branch) => (
						<tr key={branch.id}>
							<td scope="row">{branch.name}</td>

							<td>
								<button
									className="btn btn-primary ml-auto mr-1"
									onClick={() => editBranchSet(branch)}
								>
									{t("edit")}
								</button>
								<button
									className="btn btn-danger ml-auto"
									onClick={() => deleteBranchSet(branch)}
								>
									{t("delete")}
								</button>
							</td>
						</tr>
					))}
					{!isLoading && branches.length < 1 ? (
						<tr>
							<td colSpan="6">
								<p className="text-center mt-3 text-muted">
									{t("noBranches")}
								</p>
							</td>
						</tr>
					) : (
						""
					)}
				</tbody>
			</table>
			{isLoading && (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-primary"
						role="status"
					></div>
				</div>
			)}

			{/** Add Modal */}

			<Modal
				size="lg"
				show={addBranch}
				onHide={handleClose}
				aria-labelledby="add-branch-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleAdd}>
					<Modal.Header>
						<Modal.Title id="add-branch-modal">
							{t("addNewBranch")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<p className="text-left"> اسم الفرع </p>
							<input
								className="form-control"
								required
								value={branchNameAr}
								onChange={(e) =>
									branchNameArSet(e.target.value)
								}
							/>
						</div>
						<div className="form-group">
							<p className="text-left"> Branch name </p>
							<input
								className="form-control"
								required
								value={branchNameEn}
								onChange={(e) =>
									branchNameEnSet(e.target.value)
								}
							/>
						</div>
						<div className="form-group">
							<p className="text-left"> {t("area")} </p>

							<select
								className="form-control"
								value={branchArea}
								onChange={(e) => branchAreaSet(e.target.value)}
							>
								<option value=""> --{t("allAreas")}-- </option>
								{areas.map((item) => (
									<option key={item.id} value={item.id}>
										{item["name_" + i18n.language]}
									</option>
								))}
							</select>
						</div>

						<div className="form-group">
							<p className="text-left"> {t("city")} </p>

							<select
								className="form-control"
								required
								value={branchCity}
								onChange={(e) => branchCitySet(e.target.value)}
							>
								<option></option>
								{filteredCities.map((item) => (
									<option key={item.id} value={item.id}>
										{item["name_" + i18n.language]}
									</option>
								))}
							</select>
						</div>

						<div className="form-group">
							<p className="text-left"> العنوان </p>
							<textarea
								className="form-control"
								required
								rows="3"
								value={branchAddressAr}
								onChange={(e) =>
									branchAddressArSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Address </p>
							<textarea
								className="form-control"
								required
								rows="3"
								value={branchAddressEn}
								onChange={(e) =>
									branchAddressEnSet(e.target.value)
								}
							/>
						</div>
						<div className="form-group">
							<p className="text-left"> {t("branchPhone")} </p>
							<input
								className="form-control"
								required
								value={branchPhone}
								onChange={(e) => branchPhoneSet(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<p className="text-left"> Latitude</p>
							<input
								className="form-control p-1"
								required
								value={branchLatitude}
								onChange={(e) =>
									branchLatitudeSet(e.target.value)
								}
							/>
						</div>
						<div className="form-group">
							<p className="text-left"> Longitude</p>
							<input
								className="form-control p-1"
								required
								value={branchLongitude}
								onChange={(e) =>
									branchLongitudeSet(e.target.value)
								}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>
						<button
							className="btn btn-success"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("add")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Edit Modal */}

			<Modal
				size="lg"
				show={editBranch}
				onHide={handleClose}
				animation={false}
				aria-labelledby="edit-branch-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleEdit}>
					<Modal.Header>
						<Modal.Title id="edit-branch-modal">
							{t("editBranch")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{editBranchItem ? (
							<div className="d-flex justify-content-center mt-5 mb-5">
								<div
									className="spinner-border text-primary"
									role="status"
								></div>
							</div>
						) : (
							<>
								<div className="form-group">
									<p className="text-left"> اسم الفرع </p>
									<input
										className="form-control"
										required
										value={branchNameAr}
										onChange={(e) =>
											branchNameArSet(e.target.value)
										}
									/>
								</div>
								<div className="form-group">
									<p className="text-left"> Branch name </p>
									<input
										className="form-control"
										required
										value={branchNameEn}
										onChange={(e) =>
											branchNameEnSet(e.target.value)
										}
									/>
								</div>
								<div className="form-group">
									<p className="text-left"> {t("area")} </p>

									<select
										className="form-control"
										value={branchArea}
										onChange={(e) =>
											branchAreaSet(e.target.value)
										}
									>
										<option value="">
											{" "}
											--{t("allAreas")}--{" "}
										</option>
										{areas.map((item) => (
											<option
												key={item.id}
												value={item.id}
											>
												{item["name_" + i18n.language]}
											</option>
										))}
									</select>
								</div>

								<div className="form-group">
									<p className="text-left"> {t("city")} </p>

									<select
										className="form-control"
										required
										value={branchCity}
										onChange={(e) =>
											branchCitySet(e.target.value)
										}
									>
										<option></option>
										{filteredCities.map((item) => (
											<option
												key={item.id}
												value={item.id}
											>
												{item["name_" + i18n.language]}
											</option>
										))}
									</select>
								</div>

								<div className="form-group">
									<p className="text-left"> العنوان </p>
									<textarea
										className="form-control"
										required
										rows="3"
										value={branchAddressAr}
										onChange={(e) =>
											branchAddressArSet(e.target.value)
										}
									/>
								</div>

								<div className="form-group">
									<p className="text-left"> Address </p>
									<textarea
										className="form-control"
										required
										rows="3"
										value={branchAddressEn}
										onChange={(e) =>
											branchAddressEnSet(e.target.value)
										}
									/>
								</div>
								<div className="form-group">
									<p className="text-left">
										{" "}
										{t("branchPhone")}{" "}
									</p>
									<input
										className="form-control"
										required
										value={branchPhone}
										onChange={(e) =>
											branchPhoneSet(e.target.value)
										}
									/>
								</div>

								<div className="form-group">
									<p className="text-left"> Latitude</p>
									<input
										className="form-control p-1"
										required
										value={branchLatitude}
										onChange={(e) =>
											branchLatitudeSet(e.target.value)
										}
									/>
								</div>
								<div className="form-group">
									<p className="text-left"> Longitude</p>
									<input
										className="form-control p-1"
										required
										value={branchLongitude}
										onChange={(e) =>
											branchLongitudeSet(e.target.value)
										}
									/>
								</div>
							</>
						)}
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>
						<button
							className="btn btn-primary"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("edit")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Delete Modal */}

			<Modal
				size="lg"
				show={deleteBranch}
				onHide={handleClose}
				aria-labelledby="delete-category-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<Modal.Header>
					<Modal.Title id="delete-category-modal">
						{t("delete")} {deleteBranch?.name}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="text-left">{t("deleteBranch")}</p>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn" onClick={handleClose}>
						{t("cancel")}
					</button>
					<button
						className="btn btn-danger"
						onClick={handleDelete}
						disabled={isSubmitting}
					>
						{isSubmitting && (
							<span
								className="spinner-border spinner-border-sm mr-2"
								role="status"
							></span>
						)}
						{t("delete")}
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default Branches;
