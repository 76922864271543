import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

function Sizes() {
	const { t, i18n } = useTranslation();
	const [sizes, sizesSet] = useState([]);
	const [isLoading, isLoadingSet] = useState(true);
	const [addSize, addSizeSet] = useState(false);
	const [editSize, editSizeSet] = useState(false);
	const [deleteSize, deleteSizeSet] = useState(false);
	const [sizeNameAr, sizeNameArSet] = useState("");
	const [sizeNameEn, sizeNameEnSet] = useState("");
	const [isSubmitting, isSubmittingSet] = useState(false);

	function handleAdd(e) {
		e.preventDefault();
		isSubmittingSet(true);
		axios
			.post("/sizes", {
				name_en: sizeNameEn,
				name_ar: sizeNameAr,
			})
			.then(() => {
				handleClose();
				axios.get("/sizes").then((result) => {
					sizesSet(result.data);
				});
			})
			.catch((err) => console.log(err.response));
	}

	function handleEdit(e) {
		e.preventDefault();
		isSubmittingSet(true);
		axios
			.put(`/sizes/${editSize.id}`, {
				name_en: sizeNameEn,
				name_ar: sizeNameAr,
			})
			.then(() => {
				handleClose();
				axios.get("/sizes").then((result) => {
					sizesSet(result.data);
				});
			})
			.catch((err) => console.log(err.response));
	}

	function handleClose() {
		deleteSizeSet(false);
		addSizeSet(false);
		editSizeSet(false);
		isSubmittingSet(false);
		sizeNameEnSet("");
		sizeNameArSet("");
	}

	function handleDelete() {
		axios.delete(`/sizes/${deleteSize.id}`).then(() => {
			handleClose();
			axios.get("/sizes").then((result) => {
				sizesSet(result.data);
			});
		});
	}

	useEffect(() => {
		axios.get("/sizes").then((result) => {
			isLoadingSet(false);
			sizesSet(result.data);
		});
	}, []);

	useEffect(() => {
		if (editSize) {
			sizeNameEnSet(editSize.name_en);
			sizeNameArSet(editSize.name_ar);
		}
	}, [editSize]);

	return (
		<div>
			<h1 className="display-4 mb-5 text-left">{t("sizes")}</h1>
			<div className="d-flex align-items-center justifiy-content-between mb-5">
				<button
					className="btn btn-success"
					onClick={() => addSizeSet(true)}
				>
					{t("addNewSize")}
				</button>
			</div>

			<table className="table table-hover text-left">
				<thead>
					<tr>
						<th scope="col">{t("size")}</th>

						<th scope="col" style={{ width: "160px" }}>
							{t("options")}
						</th>
					</tr>
				</thead>
				<tbody>
					{sizes.map((size) => (
						<tr key={size.id}>
							<td>{size["name_" + i18n.language]}</td>

							<td>
								<button
									className="btn btn-primary ml-auto mr-1"
									onClick={() => editSizeSet(size)}
								>
									{t("edit")}
								</button>
								<button
									className="btn btn-danger ml-auto"
									onClick={() => deleteSizeSet(size)}
								>
									{t("delete")}
								</button>
							</td>
						</tr>
					))}
					{!isLoading && sizes.length < 1 ? (
						<tr>
							<td colSpan="6">
								<p className="text-center mt-3 text-muted">
									{t("noSizes")}
								</p>
							</td>
						</tr>
					) : (
						""
					)}
				</tbody>
			</table>
			{isLoading && (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-primary"
						role="status"
					></div>
				</div>
			)}

			{/** Add Modal */}

			<Modal
				size="lg"
				show={addSize}
				onHide={handleClose}
				aria-labelledby="delete-size-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleAdd}>
					<Modal.Header>
						<Modal.Title id="delete-size-modal">
							{t("addNewSize")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<p className="text-left"> الاسم </p>
							<input
								className="form-control"
								required
								value={sizeNameAr}
								onChange={(e) => sizeNameArSet(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<p className="text-left"> Name </p>
							<input
								className="form-control"
								required
								value={sizeNameEn}
								onChange={(e) => sizeNameEnSet(e.target.value)}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>

						<button
							className="btn btn-success"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("add")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Edit Modal */}

			<Modal
				size="lg"
				show={editSize}
				onHide={handleClose}
				aria-labelledby="edit-size-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleEdit}>
					<Modal.Header>
						<Modal.Title id="edit-size-modal">
							{t("edit")} {t("size")} {editSize?.name}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<p className="text-left"> الاسم </p>
							<input
								className="form-control"
								required
								value={sizeNameAr}
								onChange={(e) => sizeNameArSet(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<p className="text-left"> Name </p>
							<input
								className="form-control"
								required
								value={sizeNameEn}
								onChange={(e) => sizeNameEnSet(e.target.value)}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>

						<button
							className="btn btn-primary"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("edit")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Delete Modal */}

			<Modal
				size="lg"
				show={deleteSize}
				onHide={handleClose}
				aria-labelledby="delete-size-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<Modal.Header>
					<Modal.Title id="delete-size-modal">
						{t("delete")} {deleteSize?.name}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="text-left">{t("deleteSize")}</p>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn" onClick={handleClose}>
						{t("cancel")}
					</button>
					<button className="btn btn-danger" onClick={handleDelete}>
						{t("delete")}
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default Sizes;
