import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

function Users() {
	useLayoutEffect(() => window.scroll(0, 0), []);
	const { t, i18n } = useTranslation();
	const [users, usersSet] = useState([]);
	const [isLoading, isLoadingSet] = useState(true);

	const [pages, pagesSet] = useState(0);
	const [currentPage, currentPageSet] = useState(1);

	useEffect(() => {
		usersSet([]);
		axios
			.get("/users", {
				params: {
					page: currentPage,
				},
			})
			.then((result) => {
				usersSet(result.data.data);
				pagesSet(result.data.last_page);
				isLoadingSet(false);
			})
			.catch(() => isLoadingSet(false));
	}, [currentPage]);

	return (
		<div>
			<h1 className="display-4 mb-5 text-left">{t("users")}</h1>

			<div className="table-responsive">
				<table className="table table-hover text-left">
					<thead>
						<tr>
							<th scope="col">{t("name")}</th>
							<th scope="col">{t("email")}</th>
							<th scope="col">{t("phone")}</th>
							<th scope="col">{t("address")}</th>
						</tr>
					</thead>
					<tbody>
						{users?.map((item) => (
							<tr>
								<td>{item.name}</td>

								<td> {item.email}</td>
								<td>{item.phone}</td>

								<td>{item.address}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			{isLoading && (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-primary"
						role="status"
					></div>
				</div>
			)}

			<div className="d-flex justify-content-center">
				<Pagination
					count={pages}
					color="secondary"
					shape="rounded"
					page={currentPage}
					onChange={(e, value) => currentPageSet(value)}
					hidePrevButton
					hideNextButton
				/>
			</div>
		</div>
	);
}

export default Users;
