import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./bootstrap.all.css";
import { connect } from "react-redux";
import LoginPage from "./components/LoginPage";
import AppContainer from "./components/AppContainer";
import "./stylesheets/index.scss";
import { BrowserRouter, useHistory } from "react-router-dom";
import axios from "axios";
import actionTypes from "./redux/actionTypes";

function App({ user, setUser, setNewOrders, setNewMessages, setNewVisits }) {
	const { i18n } = useTranslation();
	const history = useHistory();
	//axios.defaults.baseURL = "https://backend.markcodes.xyz/api";
	//axios.defaults.baseURL = "http://localhost:8000/api";
	axios.defaults.baseURL = "https://services.oscarpaints.com/api";

	useEffect(() => {
		if (localStorage.getItem("lang")) {
			i18n.changeLanguage(localStorage.getItem("lang"));
		}

		if (localStorage.getItem("token")) {
			axios.defaults.headers.common[
				"Authorization"
			] = `Bearer ${localStorage.getItem("token")}`;
			setUser(true);
			axios
				.get("/orders/new")
				.then((result) => setNewOrders(result.data));
			axios
				.get("/visits/new")
				.then((result) => setNewVisits(result.data));
			axios
				.get("/messages/new")
				.then((result) => setNewMessages(result.data));
		}
	}, []);

	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			if (401 === error.response?.status) {
				localStorage.clear();
				setUser(false);
				history.push("/");
			}

			return Promise.reject(error);
		}
	);
	return (
		<BrowserRouter>
			<div className={`app ${i18n.language === "en" ? "" : "rtl"}`}>
				{user ? <AppContainer /> : <LoginPage />}
			</div>
		</BrowserRouter>
	);
}

function mapStateToProps(state) {
	return {
		user: state.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setUser: (user) => dispatch({ type: actionTypes.setUser, user: user }),
		setNewOrders: (orders) =>
			dispatch({ type: actionTypes.setNewOrders, newOrders: orders }),
		setNewVisits: (visits) =>
			dispatch({ type: actionTypes.setNewVisits, newVisits: visits }),
		setNewMessages: (messages) =>
			dispatch({
				type: actionTypes.setNewMessages,
				newMessages: messages,
			}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
