import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import actionTypes from "../redux/actionTypes";
import { useHistory } from "react-router-dom";
import logo from "../assets/logo.png";
import { IoDocumentsOutline, IoGridOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { AiOutlineShoppingCart, AiOutlineSetting } from "react-icons/ai";
import { BsHouseDoor, BsBucket, BsEnvelope, BsNewspaper } from "react-icons/bs";
import { HiOutlineColorSwatch } from "react-icons/hi";
import { GoLocation, GoLightBulb } from "react-icons/go";
import { MdLanguage } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { GiModernCity } from "react-icons/gi";
import { TiChartAreaOutline } from "react-icons/ti";
import { FiUsers } from "react-icons/fi";

function Header({
	setUser,
	newOrders,
	newVisits,
	newMessages,
	setNewOrders,
	setNewVisits,
	setNewMessages,
}) {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	function changeLanguage() {
		if (i18n.language === "en") {
			i18n.changeLanguage("ar");
			localStorage.setItem("lang", "ar");
		} else {
			i18n.changeLanguage("en");
			localStorage.setItem("lang", "en");
		}
	}

	function logout() {
		localStorage.clear();
		setUser(false);
		history.push("/");
	}
	return (
		<Navbar
			bg="light"
			collapseOnSelect
			expand="lg"
			className="shadow d-print-none"
		>
			<Container>
				<Navbar.Brand href="#home">
					<img
						src={logo}
						height="30"
						className="d-inline-block align-top mr-5"
						alt="React Bootstrap logo"
					/>
				</Navbar.Brand>

				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse
					id="basic-navbar-nav"
					className="justify-content-between"
				>
					<div className="navbar-brand mb-0 h1 d-none d-lg-block font-weight-normal">
						{t("controlPanel")}
					</div>

					<Nav className="d-lg-none d-block">
						<Nav.Link
							to="/orders"
							exact
							eventKey="1"
							className="nav-link d-flex align-items-center"
							as={NavLink}
							onClick={() =>
								setTimeout(() => setNewOrders(0), [1000])
							}
						>
							<IoDocumentsOutline className="mr-1 nav-icon" />

							{t("orders")}
							{newOrders !== 0 && (
								<span className="badge badge-danger ml-1">
									{newOrders}
								</span>
							)}
						</Nav.Link>
						<Nav.Link
							to="/visits"
							exact
							eventKey="2"
							as={NavLink}
							className="nav-link d-flex align-items-center"
							onClick={() =>
								setTimeout(() => setNewVisits(0), [1000])
							}
						>
							<BsHouseDoor className="mr-1 nav-icon" />

							{t("visits")}
							{newVisits !== 0 && (
								<span className="badge badge-danger ml-1">
									{newVisits}
								</span>
							)}
						</Nav.Link>
						<Nav.Link
							to="/products"
							exact
							eventKey="3"
							as={NavLink}
							className="nav-link d-flex align-items-center"
						>
							<AiOutlineShoppingCart className="mr-1 nav-icon" />

							{t("products")}
						</Nav.Link>
						<Nav.Link
							to="/colors"
							exact
							eventKey="4"
							as={NavLink}
							className="nav-link d-flex align-items-center"
						>
							<HiOutlineColorSwatch className="mr-1 nav-icon" />

							{t("colors")}
						</Nav.Link>
						<Nav.Link
							to="/categories"
							exact
							eventKey="5"
							as={NavLink}
							className="nav-link d-flex align-items-center"
						>
							<IoGridOutline className="mr-1 nav-icon" />

							{t("categories")}
						</Nav.Link>

						<Nav.Link
							to="/sizes"
							exact
							eventKey="8"
							as={NavLink}
							className="nav-link d-flex align-items-center"
						>
							<BsBucket className="mr-1 nav-icon" />

							{t("sizes")}
						</Nav.Link>
						<Nav.Link
							to="/sheen"
							exact
							eventKey="9"
							as={NavLink}
							className="nav-link d-flex align-items-center"
						>
							<GoLightBulb className="mr-1 nav-icon" />

							{t("sheenLevels")}
						</Nav.Link>
						<Nav.Link
							to="/branches"
							exact
							eventKey="6"
							as={NavLink}
							className="nav-link d-flex align-items-center"
						>
							<GoLocation className="mr-1 nav-icon" />

							{t("branches")}
						</Nav.Link>
						<Nav.Link
							to="/areas"
							exact
							eventKey="10"
							as={NavLink}
							className="nav-link d-flex align-items-center"
						>
							<TiChartAreaOutline className="mr-1 nav-icon" />

							{t("areas")}
						</Nav.Link>
						<Nav.Link
							to="/cities"
							exact
							eventKey="11"
							as={NavLink}
							className="nav-link d-flex align-items-center"
						>
							<GiModernCity className="mr-1 nav-icon" />

							{t("cities")}
						</Nav.Link>
						<Nav.Link
							to="/messages"
							exact
							eventKey="7"
							as={NavLink}
							className="nav-link d-flex align-items-center"
							onClick={() =>
								setTimeout(() => setNewMessages(0), [1000])
							}
						>
							<BsEnvelope className="mr-1 nav-icon" />

							{t("messages")}
							{newMessages !== 0 && (
								<span className="badge badge-danger ml-1">
									{newMessages}
								</span>
							)}
						</Nav.Link>
						<Nav.Link
							to="/users"
							exact
							eventKey="11"
							as={NavLink}
							className="nav-link d-flex align-items-center"
						>
							<FiUsers className="mr-1 nav-icon" />

							{t("users")}
						</Nav.Link>
						<Nav.Link
							to="/newsletter"
							exact
							eventKey="12"
							as={NavLink}
							className="nav-link d-flex align-items-center"
						>
							<BsNewspaper className="mr-1 nav-icon" />

							{t("newsletter")}
						</Nav.Link>
						<Nav.Link
							to="/settings"
							exact
							eventKey="10"
							as={NavLink}
							className="nav-link d-flex align-items-center"
						>
							<AiOutlineSetting className="mr-1 nav-icon" />

							{t("settings")}
						</Nav.Link>
					</Nav>
					<Nav>
						<button
							className="btn text-left"
							onClick={changeLanguage}
						>
							<MdLanguage className="mr-1 nav-icon" />
							{t("switchLang")}
						</button>
						<button className="btn text-left" onClick={logout}>
							<AiOutlineLogout className="mr-1 nav-icon" />
							{t("logout")}
						</button>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

function mapStateToProps(state) {
	return {
		newOrders: state.newOrders,
		newVisits: state.newVisits,
		newMessages: state.newMessages,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setUser: (user) => dispatch({ type: actionTypes.setUser, user: user }),
		setNewOrders: (orders) =>
			dispatch({ type: actionTypes.setNewOrders, newOrders: orders }),
		setNewVisits: (visits) =>
			dispatch({ type: actionTypes.setNewVisits, newVisits: visits }),
		setNewMessages: (messages) =>
			dispatch({
				type: actionTypes.setNewMessages,
				newMessages: messages,
			}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

/*
<li className="list-group-item border-0">
					<NavLink to="/orders" className="sidebar__link text-left">
						<IoDocumentsOutline className="nav-icon mr-2" />
						{t("orders")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/visits" className="sidebar__link text-left">
						<BsHouseDoor className="nav-icon mr-2" />
						{t("visits")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/products" className="sidebar__link text-left">
						<AiOutlineShoppingCart className="nav-icon mr-2" />
						{t("products")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/colors" className="sidebar__link text-left">
						<HiOutlineColorSwatch className="nav-icon mr-2" />
						{t("colors")}
					</NavLink>
				</li>
				{/* <li className="list-group-item border-0">
					<NavLink to="/catalogs" className="sidebar__link text-left">
						<RiGalleryLine className="nav-icon mr-2" />
						{t("catalogs")}
					</NavLink>
				</li> 
			<li className="list-group-item border-0">
					<NavLink
						to="/best-products"
						className="sidebar__link text-left"
					>
						<BsBookmark className="nav-icon mr-2" />
						{t("bestProducts")}
					</NavLink>
				</li> 
				<li className="list-group-item border-0">
					<NavLink
						to="/categories"
						className="sidebar__link text-left"
					>
						<IoGridOutline className="nav-icon mr-2" />
						{t("categories")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/branches" className="sidebar__link text-left">
						<GoLocation className="nav-icon mr-2" />
						{t("branches")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/messages" className="sidebar__link text-left">
						<BsEnvelope className="nav-icon mr-2" />
						{t("messages")}
					</NavLink>
				</li>
*/
