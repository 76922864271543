import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdLanguage } from "react-icons/md";
import logo from "../assets/logo.png";
import { connect } from "react-redux";
import actionTypes from "../redux/actionTypes";
import axios from "axios";

function LoginPage({ setUser }) {
	const { t, i18n } = useTranslation();
	const [email, emailSet] = useState("");
	const [password, passwordSet] = useState("");
	const [isSubmitting, isSubmittingSet] = useState(false);
	const [error, errorSet] = useState("");

	function changeLang() {
		if (i18n.language === "en") {
			i18n.changeLanguage("ar");
			localStorage.setItem("lang", "ar");
		} else {
			i18n.changeLanguage("en");
			localStorage.setItem("lang", "en");
		}
	}

	function handleSubmit(e) {
		e.preventDefault();
		isSubmittingSet(true);
		axios
			.post("/auth/admin", {
				email: email,
				password: password,
			})
			.then((result) => {
				localStorage.setItem("token", result.data.token);
				axios.defaults.headers.common[
					"Authorization"
				] = `Bearer ${result.data.token}`;
				isSubmittingSet(false);
				setUser(true);
			})
			.catch((err) => {
				errorSet(err.response.data.message);
				isSubmittingSet(false);
			});
		// setUser(true);
	}

	return (
		<div className="container d-flex flex-column justify-content-between h-100 p-3 align-items-center">
			<div className="d-flex flex-row-reverse w-100">
				<button className="btn" onClick={changeLang}>
					<MdLanguage /> {t("switchLang")}
				</button>
			</div>

			<div className="shadow rounded">
				<div className="p-5">
					<img
						src={logo}
						alt=""
						className="login__logo mb-3 mr-auto ml-auto d-block"
					/>
					<h1
						className="display-4 mb-5 text-center"
						style={{ fontSize: "25px" }}
					>
						{t("controlPanel")}
					</h1>
					{error && (
						<div className="alert alert-danger" role="alert">
							{error}
						</div>
					)}
					<form onSubmit={handleSubmit}>
						<div className="form-group">
							<input
								type="email"
								className="form-control"
								id="login-email"
								value={email}
								required
								onChange={(e) => emailSet(e.target.value)}
								placeholder={t("email")}
							/>
						</div>
						<div className="form-group">
							<input
								type="password"
								className="form-control"
								id="login-password"
								value={password}
								required
								onChange={(e) => passwordSet(e.target.value)}
								placeholder={t("password")}
							/>
						</div>

						<button
							type="submit"
							className="btn btn-success btn-block"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("login")}
						</button>
					</form>
				</div>
			</div>

			<p className="text-muted">{t("footer")}</p>
		</div>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		setUser: (user) => dispatch({ type: actionTypes.setUser, user: user }),
	};
}

export default connect(null, mapDispatchToProps)(LoginPage);
