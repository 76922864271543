import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import ReactToPrint from "react-to-print";
import Pagination from "@material-ui/lab/Pagination";

function Orders() {
	const { t, i18n } = useTranslation();
	const [visit, visitSet] = useState(false);
	const [visitStatus, visitStatusSet] = useState("");
	const [visitDetails, visitDetailsSet] = useState({});
	const [visits, visitsSet] = useState([]);
	const [isLoading, isLoadingSet] = useState(true);
	const [isLoadingOrder, isLoadingOrderSet] = useState(false);
	const [isChangingStatus, isChangingStatusSet] = useState(false);

	const [pages, pagesSet] = useState(0);
	const [currentPage, currentPageSet] = useState(1);
	const [status, statusSet] = useState("all");

	const messageRef = useRef();

	useEffect(() => {
		isLoadingSet(true);
		visitsSet([]);
		axios
			.get("/visits", {
				params: {
					page: currentPage,
					status: status === "all" ? undefined : status,
				},
			})
			.then((result) => {
				visitsSet(result.data.data);
				pagesSet(result.data.meta.last_page);
				isLoadingSet(false);
			});
	}, [currentPage, status]);

	useEffect(() => {
		if (visit) {
			visitStatusSet(visit.status);
		}
	}, [visit]);

	useEffect(() => {
		currentPageSet(1);
	}, [status]);

	function handleChangeStatus(e) {
		isChangingStatusSet(true);
		visitStatusSet(e.target.value);
		axios
			.put(`/visits/${visit?.id}`, { status: e.target.value })
			.then(() => {
				isChangingStatusSet(false);
				isLoadingSet(true);
				visitsSet([]);
				axios
					.get("/visits", {
						params: {
							page: currentPage,
							status: status === "all" ? undefined : status,
						},
					})
					.then((result) => {
						visitsSet(result.data.data);
						isLoadingSet(false);
					});
			});
	}

	function handleClose() {
		visitSet(false);
		visitDetailsSet({});
		visitStatusSet("");
		isLoadingOrderSet(false);
		isChangingStatusSet(false);
	}
	return (
		<div>
			<h1 className="display-4 mb-5 text-left">{t("ourServices")}</h1>
			<div className="form-group row">
				<label
					htmlFor="filter-select"
					className="col-xs-3 col-form-label mr-2"
				>
					{t("status")}
				</label>
				<div className="col-xs-9">
					<select
						className="form-control"
						id="filter-select"
						value={status}
						onChange={(e) => statusSet(e.target.value)}
					>
						<option value="all">{t("all")}</option>
						<option value="new">{t("new")}</option>
						<option value="in_process">{t("inProcess")}</option>
						<option value="done">{t("done")}</option>
						<option value="canceled">{t("canceled")}</option>
					</select>
				</div>
			</div>
			<div className="table-responsive">
				<table className="table table-hover text-left">
					<thead>
						<tr>
							<th scope="col">{t("client")}</th>

							<th scope="col">{t("type")}</th>
							<th scope="col">{t("date")}</th>
							<th scope="col">{t("status")}</th>
							<th scope="col" style={{ width: "150px" }}>
								{t("options")}
							</th>
						</tr>
					</thead>
					<tbody>
						{visits.map((item) => (
							<tr
								style={{
									fontWeight:
										item.is_read == 1 ? "700" : "400",
								}}
							>
								<td>{item.name}</td>

								<td>
									{item?.type === "home" && t("homePlace")}
									{item?.type === "business" &&
										t("businessPlace")}
									{item?.type === "consultation" &&
										t("consultation")}
								</td>
								<td>{item.created_at.slice(0, 10)}</td>
								<td>
									{item.status === "new" && (
										<span class="badge badge-secondary">
											{t("new")}
										</span>
									)}
									{item.status === "in_process" && (
										<span class="badge badge-warning">
											{t("inProcess")}
										</span>
									)}
									{item.status === "done" && (
										<span class="badge badge-success">
											{t("done")}
										</span>
									)}
									{item.status === "canceled" && (
										<span class="badge badge-danger">
											{t("canceled")}
										</span>
									)}
								</td>
								<td>
									<button
										className="btn btn-primary ml-auto"
										onClick={() => visitSet(item)}
									>
										{t("details")}
									</button>
								</td>
							</tr>
						))}

						{!isLoading && visits.length < 1 ? (
							<tr>
								<td colSpan="6">
									<p className="text-center mt-3 text-muted">
										{t("noVisits")}
									</p>
								</td>
							</tr>
						) : (
							""
						)}
					</tbody>
				</table>
			</div>
			{isLoading && (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-primary"
						role="status"
					></div>
				</div>
			)}

			<div className="d-flex justify-content-center">
				<Pagination
					count={pages}
					color="secondary"
					shape="rounded"
					page={currentPage}
					onChange={(e, value) => currentPageSet(value)}
					hidePrevButton
					hideNextButton
				/>
			</div>

			<Modal
				size="lg"
				show={visit}
				onHide={handleClose}
				aria-labelledby="visit-modal"
				dialogClassName="modal-90w"
				animation={false}
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<Modal.Header>
					<Modal.Title id="visit-modal">
						{t("ourServices")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row m-1 mb-3" style={{ maxWidth: "400px" }}>
						<label
							htmlFor="filter-select"
							className="col-xs-3 col-form-label mr-2"
						>
							<strong>{t("status")}</strong>
						</label>
						<div className="col-xs-9">
							<select
								className="form-control"
								id="filter-select"
								value={visitStatus}
								disabled={isChangingStatus}
								onChange={(e) => handleChangeStatus(e)}
							>
								<option value="new">{t("new")}</option>
								<option value="in_process">
									{t("inProcess")}
								</option>
								<option value="done">{t("done")}</option>
								<option value="canceled">
									{t("canceled")}
								</option>
							</select>
						</div>
					</div>
					{isChangingStatus && (
						<div className="d-flex mt-1 mb-2">
							<div
								className="spinner-border text-primary"
								role="status"
							></div>
						</div>
					)}
					<div
						className={`text-left mt-3 ${
							i18n.language === "ar" ? "text__right" : ""
						}`}
						style={{
							fontSize: "18px",
							lineHeight: "2",
							padding: "10px",
						}}
						ref={messageRef}
					>
						<h1 className="text-center mt-5 mb-5 d-none d-print-block">
							{t("ourServices")}
						</h1>
						<strong>{t("name")}:</strong>{" "}
						<br className="d-none d-print-block" />
						{visit?.name}
						<br />
						<strong>{t("email")}:</strong>{" "}
						<br className="d-none d-print-block" />
						{visit?.email}
						<br />
						<strong>{t("type")}:</strong>{" "}
						<br className="d-none d-print-block" />
						{visit?.type === "home"
							? t("homePlace")
							: t("businessPlace")}
						<br />
						<strong>{t("phone")}:</strong>{" "}
						<br className="d-none d-print-block" />
						{visit?.phone}
						<br />
						<strong>{t("address")}:</strong>{" "}
						<br className="d-none d-print-block" />
						{visit?.address}
						<br />
						<strong>{t("date")}:</strong>{" "}
						<br className="d-none d-print-block" />
						{visit?.created_at?.slice(0, 10)} <br />
						<strong>{t("additionalNote")}:</strong>{" "}
						<br className="d-none d-print-block" />
						{visit?.note} <br />
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn" onClick={handleClose}>
						{t("close")}
					</button>

					<ReactToPrint
						trigger={() => (
							<button className="btn btn-primary">
								{t("print")}
							</button>
						)}
						content={() => messageRef.current}
					/>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default Orders;
