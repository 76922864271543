import actionTypes from "./actionTypes";

const initalState = {
	user: false,
	newMessages: 0,
	newOrders: 0,
	newVisits: 0,
};

const reducer = (state = initalState, action) => {
	switch (action.type) {
		case actionTypes.setUser:
			return {
				...state,
				user: action.user,
			};

		case actionTypes.setNewOrders:
			return {
				...state,
				newOrders: action.newOrders,
			};

		case actionTypes.setNewVisits:
			return {
				...state,
				newVisits: action.newVisits,
			};

		case actionTypes.setNewMessages:
			return {
				...state,
				newMessages: action.newMessages,
			};

		default:
			return state;
	}
};

export default reducer;
