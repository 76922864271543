import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEn from "./locales/en.json";
import translationAr from "./locales/ar.json";

const resources = {
	en: {
		translation: translationEn,
	},
	ar: {
		translation: translationAr,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lang: "en",
	fallbackLng: "en",
	keySeparator: false,
	interpolation: { escapeValue: false },
	useSuspense: true,
});

export default i18n;
