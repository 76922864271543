import React from "react";
import { NavLink } from "react-router-dom";
import { IoDocumentsOutline, IoGridOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { AiOutlineShoppingCart, AiOutlineSetting } from "react-icons/ai";
import { BsHouseDoor, BsBucket, BsEnvelope, BsNewspaper } from "react-icons/bs";
import { HiOutlineColorSwatch } from "react-icons/hi";
import { RiGalleryLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { GoLocation, GoLightBulb } from "react-icons/go";
import { TiChartAreaOutline } from "react-icons/ti";
import { GiModernCity } from "react-icons/gi";
import actionTypes from "../redux/actionTypes";
import { connect } from "react-redux";

function Sidebar({
	newOrders,
	newVisits,
	newMessages,
	setNewOrders,
	setNewVisits,
	setNewMessages,
}) {
	const { t } = useTranslation();
	return (
		<div className="col-lg-3 d-none d-lg-block d-print-none">
			<ul className="list-group shadow m-2 border-0 pb-3">
				<li className="list-group-item border-0">
					<NavLink
						to="/orders"
						className="sidebar__link text-left"
						onClick={() =>
							setTimeout(() => setNewOrders(0), [1000])
						}
					>
						<IoDocumentsOutline className="nav-icon mr-2" />
						{t("orders")}
						{newOrders !== 0 && (
							<span className="badge badge-danger ml-1">
								{newOrders}
							</span>
						)}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink
						to="/visits"
						className="sidebar__link text-left"
						onClick={() =>
							setTimeout(() => setNewVisits(0), [1000])
						}
					>
						<BsHouseDoor className="nav-icon mr-2" />
						{t("ourServices")}
						{newVisits !== 0 && (
							<span className="badge badge-danger ml-1">
								{newVisits}
							</span>
						)}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/products" className="sidebar__link text-left">
						<AiOutlineShoppingCart className="nav-icon mr-2" />
						{t("products")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/colors" className="sidebar__link text-left">
						<HiOutlineColorSwatch className="nav-icon mr-2" />
						{t("colors")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/catalogs" className="sidebar__link text-left">
						<RiGalleryLine className="nav-icon mr-2" />
						{t("catalogs")}
					</NavLink>
				</li>

				<li className="list-group-item border-0">
					<NavLink
						to="/categories"
						className="sidebar__link text-left"
					>
						<IoGridOutline className="nav-icon mr-2" />
						{t("categories")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/sizes" className="sidebar__link text-left">
						<BsBucket className="nav-icon mr-2" />
						{t("sizes")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/sheen" className="sidebar__link text-left">
						<GoLightBulb className="nav-icon mr-2" />
						{t("sheenLevels")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/branches" className="sidebar__link text-left">
						<GoLocation className="nav-icon mr-2" />
						{t("branches")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/areas" className="sidebar__link text-left">
						<TiChartAreaOutline className="nav-icon mr-2" />
						{t("areas")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/cities" className="sidebar__link text-left">
						<GiModernCity className="nav-icon mr-2" />
						{t("cities")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink
						to="/messages"
						className="sidebar__link text-left"
						onClick={() =>
							setTimeout(() => setNewMessages(0), [1000])
						}
					>
						<BsEnvelope className="nav-icon mr-2" />
						{t("messages")}
						{newMessages !== 0 && (
							<span className="badge badge-danger ml-1">
								{newMessages}
							</span>
						)}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/users" className="sidebar__link text-left">
						<FiUsers className="nav-icon mr-2" />
						{t("users")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink
						to="/newsletter"
						className="sidebar__link text-left"
					>
						<BsNewspaper className="nav-icon mr-2" />
						{t("newsletter")}
					</NavLink>
				</li>
				<li className="list-group-item border-0">
					<NavLink to="/settings" className="sidebar__link text-left">
						<AiOutlineSetting className="nav-icon mr-2" />
						{t("settings")}
					</NavLink>
				</li>
			</ul>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		newOrders: state.newOrders,
		newVisits: state.newVisits,
		newMessages: state.newMessages,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setNewOrders: (orders) =>
			dispatch({ type: actionTypes.setNewOrders, newOrders: orders }),
		setNewVisits: (visits) =>
			dispatch({ type: actionTypes.setNewVisits, newVisits: visits }),
		setNewMessages: (messages) =>
			dispatch({
				type: actionTypes.setNewMessages,
				newMessages: messages,
			}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
