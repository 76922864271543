import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

function ProductShow() {
	const [isLoading, isLoadingSet] = useState(true);
	const { productId } = useParams();
	const [product, productSet] = useState({});
	const [productSheen, productSheenSet] = useState("");
	const [productSize, productSizeSet] = useState("");
	const [productPrice, productPriceSet] = useState("");

	const [sizes, sizesSet] = useState([]);
	const [sheens, sheensSet] = useState([]);
	const [images, imagesSet] = useState([]);
	const [image, imageSet] = useState({});
	const [prices, pricesSet] = useState([]);
	const [colors, colorsSet] = useState([]);
	const [selectedColor, selectedColorSet] = useState("");
	const [errors, errorsSet] = useState([]);
	const [addPrice, addPriceSet] = useState(false);
	const [addImage, addImageSet] = useState(false);
	const [editPrice, editPriceSet] = useState(false);
	const [deletePrice, deletePriceSet] = useState("");
	const [deleteImage, deleteImageSet] = useState("");

	const { t, i18n } = useTranslation();
	const [deleteProduct, deleteProductSet] = useState(false);
	const history = useHistory();
	const [isSubmitting, isSubmittingSet] = useState(false);

	useEffect(() => {
		axios.get(`/products/${productId}/all`).then((result) => {
			isLoadingSet(false);
			productSet(result.data.data);
			pricesSet(result.data.data.prices);
			imagesSet(result.data.data.images);
		});
		axios.get(`/sheen`).then((result) => {
			sheensSet(result.data);
		});
		axios.get(`/sizes`).then((result) => {
			sizesSet(result.data);
		});
		axios.get(`/colors`).then((result) => {
			colorsSet(result.data);
		});
	}, []);

	useEffect(() => {
		if (editPrice) {
			productSheenSet(editPrice.sheen_id);
			productPriceSet(editPrice.price);
			productSizeSet(editPrice.size_id);
			selectedColorSet(editPrice.color_id);
		}
	}, [editPrice]);

	useEffect(() => {
		if (deletePrice) {
			axios
				.delete(`/products/${productId}/prices/${deletePrice}`)
				.then((result) => {
					pricesSet(result.data);
					deletePriceSet("");
				});
		}
	}, [deletePrice]);

	function handleClose() {
		addPriceSet(false);
		editPriceSet(false);
		productSheenSet("");
		productPriceSet("");
		productSizeSet("");
		addImageSet(false);
		errorsSet([]);
		productSizeSet("");
		productSheenSet("");
		imageSet({});
		selectedColorSet("");
	}

	function handleAddImage(e) {
		e.preventDefault();
		errorsSet([]);
		isSubmittingSet(true);
		let formData = new FormData();
		formData.append("image", image);

		axios
			.post(`/products/${productId}/images`, formData)
			.then((result) => {
				isSubmittingSet(false);
				imagesSet(result.data.data);
				handleClose();
			})
			.catch((err) => {
				isSubmittingSet(false);
				if (err.response.data.errors) {
					errorsSet(err.response.data.errors);
				}
			});
	}

	useEffect(() => {
		if (deleteImage) {
			axios
				.delete(`/products/${productId}/images/${deleteImage}`)
				.then((result) => {
					imagesSet(result.data.data);
					deleteImageSet("");
				});
		}
	}, [deleteImage]);

	function handleAdd(e) {
		e.preventDefault();
		isSubmittingSet(true);
		axios
			.post(`/products/${productId}/prices`, {
				price: productPrice,
				size_id: productSize ? productSize : undefined,
				sheen_id: productSheen ? productSheen : undefined,
				color_id: selectedColor ? selectedColor : undefined,
			})
			.then((result) => {
				isSubmittingSet(false);
				pricesSet(result.data);
				handleClose();
			})
			.catch((err) => console.log(err.response));
	}

	function handleEdit(e) {
		e.preventDefault();
		isSubmittingSet(true);
		axios
			.put(`/products/${productId}/prices/${editPrice.id}`, {
				price: productPrice,
				size_id: productSize,
				sheen_id: productSheen,
				color_id: selectedColor ? selectedColor : undefined,
			})
			.then((result) => {
				isSubmittingSet(false);
				pricesSet(result.data);
				handleClose();
			})
			.catch((err) => console.log(err.response));
	}

	function handleDelete() {
		isSubmittingSet(true);
		axios
			.delete(`/products/${productId}`)
			.then((result) => {
				isSubmittingSet(false);
				history.push("/products");
			})
			.catch((err) => console.log(err.response));
	}
	if (isLoading) {
		return (
			<div className="d-flex justify-content-center mt-5">
				<div
					className="spinner-border text-primary"
					role="status"
				></div>
			</div>
		);
	} else {
		return (
			<div className="text-left pb-5 mb-5">
				<h1 className="display-4 mb-5 text-left">
					{product["name_" + i18n.language]}
				</h1>
				{/* <h4>{product.category.name_en}</h4> */}
				<div className="d-flex align-items-center justifiy-content-between mb-5">
					<Link
						className="btn btn-primary"
						to={`/products/${productId}/edit`}
					>
						{t("editProduct")}
					</Link>
					<button
						className="btn btn-danger ml-2"
						onClick={() => deleteProductSet(true)}
					>
						{t("deleteProduct")}
					</button>
				</div>
				<img
					src={product.image}
					className="mr-auto d-block ml-auto mb-5"
					style={{ height: "300px", objectFit: "contain" }}
					alt=""
				/>

				<h3>{t("Images")}</h3>
				<button
					className="btn btn-success mb-3"
					onClick={() => addImageSet(true)}
				>
					{t("addImage")}
				</button>

				<table className="table">
					<thead>
						<tr>
							<th className="text-left">{t("image")}</th>
							<th
								className="text-center"
								style={{ width: "150px" }}
							>
								{t("options")}
							</th>
						</tr>
					</thead>
					<tbody>
						{images.length > 0 ? (
							images.map((item) => (
								<tr>
									<td>
										<img
											src={item.image}
											alt=""
											style={{
												width: "150px",
												height: "150",
												objectFit: "contain",
											}}
										/>
									</td>

									<td style={{ width: "150px" }}>
										<button
											disabled={deleteImage === item.id}
											className="btn btn-danger ml-auto"
											onClick={() =>
												deleteImageSet(item.id)
											}
										>
											{deleteImage === item.id && (
												<span
													className="spinner-border spinner-border-sm mr-2"
													role="status"
												></span>
											)}
											{t("delete")}
										</button>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan="4">
									<p className="text-center mt-3 text-muted">
										{t("noImages")}
									</p>
								</td>
							</tr>
						)}
					</tbody>
				</table>

				<h3>{t("prices")}</h3>
				<button
					className="btn btn-success mb-3"
					onClick={() => addPriceSet(true)}
				>
					{t("addPrice")}
				</button>

				<table className="table">
					<thead>
						<tr>
							<th className="text-left">{t("color")}</th>
							<th className="text-left">{t("sheenLevel")}</th>
							<th className="text-left">{t("size")}</th>
							<th className="text-left">{t("price")}</th>
							<th className="text-center">{t("options")}</th>
						</tr>
					</thead>
					<tbody>
						{prices.length > 0 ? (
							prices.map((item) => (
								<tr>
									<td scope="row">
										<div
											style={{
												width: "25px",
												height: "25px",
												backgroundColor:
													item.color?.value,
											}}
										></div>
										{item.color?.name}
									</td>
									<td>
										{item.sheen
											? item.sheen[
													`name_${i18n.language}`
											  ]
											: ""}
									</td>
									<td>
										{item.size["name_" + i18n.language] ||
											""}
									</td>
									<td>
										{item.price} {t("sar")}
									</td>
									<td>
										<button
											className="btn btn-primary ml-auto mr-1"
											onClick={() => editPriceSet(item)}
										>
											{t("edit")}
										</button>
										<button
											disabled={deletePrice === item.id}
											className="btn btn-danger ml-auto"
											onClick={() =>
												deletePriceSet(item.id)
											}
										>
											{deletePrice === item.id && (
												<span
													className="spinner-border spinner-border-sm mr-2"
													role="status"
												></span>
											)}
											{t("delete")}
										</button>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan="5">
									<p className="text-center mt-3 text-muted">
										{t("noPrices")}
									</p>
								</td>
							</tr>
						)}
					</tbody>
				</table>

				{/** Add Image Modal */}

				<Modal
					size="lg"
					show={addImage}
					onHide={handleClose}
					aria-labelledby="add-price-modal"
					className={i18n.language === "en" ? "" : "rtl"}
				>
					<Modal.Header>
						<Modal.Title id="add-price-modal">
							{t("addImage")}
						</Modal.Title>
					</Modal.Header>
					<form onSubmit={handleAddImage}>
						<Modal.Body>
							<div className="form-group">
								<p className="text-left">{t("image")}</p>

								<input
									className="form-control p-1"
									type="file"
									required
									accept="image/*"
									onChange={(e) =>
										imageSet(e.target.files[0])
									}
								/>
								{errors["image"] && (
									<p className="text-danger">
										{errors["image"]}
									</p>
								)}
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button className="btn" onClick={handleClose}>
								{t("cancel")}
							</button>
							<button
								className="btn btn-success"
								type="submit"
								disabled={isSubmitting}
							>
								{isSubmitting && (
									<span
										className="spinner-border spinner-border-sm mr-2"
										role="status"
									></span>
								)}
								{t("add")}
							</button>
						</Modal.Footer>
					</form>
				</Modal>

				{/** Add Modal */}

				<Modal
					size="lg"
					show={addPrice}
					onHide={handleClose}
					aria-labelledby="add-price-modal"
					className={i18n.language === "en" ? "" : "rtl"}
				>
					<Modal.Header>
						<Modal.Title id="add-price-modal">
							{t("addPrice")}
						</Modal.Title>
					</Modal.Header>
					<form onSubmit={handleAdd}>
						<Modal.Body>
							<div className="form-group">
								<p className="text-left">{t("color")}</p>
								<FormControl
									variant="outlined"
									fullWidth
									style={{
										marginBottom: "20px",
										border: "1px solid lightgray",
									}}
								>
									<Select
										value={selectedColor}
										onChange={(e) =>
											selectedColorSet(e.target.value)
										}
									>
										{colors.map((color) => (
											<MenuItem
												key={color.id}
												value={color.id}
											>
												<div className="d-flex align-items-center">
													<div
														className="mr-3"
														style={{
															width: "40px",
															height: "40px",
															backgroundColor:
																color.value,
														}}
													></div>
													<div>{color.name}</div>
												</div>
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<p className="text-left">{t("sheenLevel")}</p>
								<select
									className="form-control"
									value={productSheen}
									onChange={(e) =>
										productSheenSet(e.target.value)
									}
								>
									<option></option>
									{sheens.map((sheen) => (
										<option key={sheen.id} value={sheen.id}>
											{sheen[`name_${i18n.language}`]}
										</option>
									))}
								</select>
							</div>
							<div className="form-group">
								<p className="text-left">{t("size")}</p>
								<select
									className="form-control"
									value={productSize}
									onChange={(e) =>
										productSizeSet(e.target.value)
									}
								>
									<option></option>
									{sizes.map((size) => (
										<option key={size.id} value={size.id}>
											{size["name_" + i18n.language]}
										</option>
									))}
								</select>
							</div>
							<div className="form-group">
								<p className="text-left">{t("price")}</p>
								<input
									className="form-control"
									required
									type="number"
									value={productPrice}
									onChange={(e) =>
										productPriceSet(e.target.value)
									}
								/>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button className="btn" onClick={handleClose}>
								{t("cancel")}
							</button>
							<button
								className="btn btn-success"
								type="submit"
								disabled={isSubmitting}
							>
								{isSubmitting && (
									<span
										className="spinner-border spinner-border-sm mr-2"
										role="status"
									></span>
								)}
								{t("add")}
							</button>
						</Modal.Footer>
					</form>
				</Modal>

				{/** Edit Modal */}

				<Modal
					size="lg"
					show={editPrice}
					onHide={handleClose}
					aria-labelledby="add-price-modal"
					className={i18n.language === "en" ? "" : "rtl"}
				>
					<Modal.Header>
						<Modal.Title id="add-price-modal">
							{t("editPrice")}
						</Modal.Title>
					</Modal.Header>
					<form onSubmit={handleEdit}>
						<Modal.Body>
							<div className="form-group">
								<p className="text-left">{t("color")}</p>
								<FormControl
									variant="outlined"
									fullWidth
									style={{
										marginBottom: "20px",
										border: "1px solid lightgray",
									}}
								>
									<Select
										value={selectedColor}
										onChange={(e) =>
											selectedColorSet(e.target.value)
										}
									>
										{colors.map((color) => (
											<MenuItem
												key={color.id}
												value={color.id}
											>
												<div className="d-flex align-items-center">
													<div
														className="mr-3"
														style={{
															width: "40px",
															height: "40px",
															backgroundColor:
																color.value,
														}}
													></div>
													<div>{color.name}</div>
												</div>
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<p className="text-left">{t("sheenLevel")}</p>
								<select
									className="form-control"
									value={productSheen}
									onChange={(e) =>
										productSheenSet(e.target.value)
									}
								>
									<option></option>
									{sheens.map((sheen) => (
										<option key={sheen.id} value={sheen.id}>
											{sheen[`name_${i18n.language}`]}
										</option>
									))}
								</select>
							</div>
							<div className="form-group">
								<p className="text-left">{t("size")}</p>
								<select
									className="form-control"
									value={productSize}
									onChange={(e) =>
										productSizeSet(e.target.value)
									}
								>
									<option></option>
									{sizes.map((size) => (
										<option key={size.id} value={size.id}>
											{size["name_" + i18n.language]}
										</option>
									))}
								</select>
							</div>
							<div className="form-group">
								<p className="text-left">{t("price")}</p>
								<input
									className="form-control"
									required
									type="number"
									value={productPrice}
									onChange={(e) =>
										productPriceSet(e.target.value)
									}
								/>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button className="btn" onClick={handleClose}>
								{t("cancel")}
							</button>
							<button
								className="btn btn-primary"
								type="submit"
								disabled={isSubmitting}
							>
								{isSubmitting && (
									<span
										className="spinner-border spinner-border-sm mr-2"
										role="status"
									></span>
								)}
								{t("edit")}
							</button>
						</Modal.Footer>
					</form>
				</Modal>

				{/** Delete Modal */}

				<Modal
					size="lg"
					show={deleteProduct}
					onHide={() => deleteProductSet(false)}
					aria-labelledby="delete-product-modal"
					className={i18n.language === "en" ? "" : "rtl"}
				>
					<Modal.Header>
						<Modal.Title id="delete-product-modal">
							{t("delete")} {product["name_" + i18n.language]}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p className="text-left">{t("confirmDeleteProduct")}</p>
					</Modal.Body>
					<Modal.Footer>
						<button
							className="btn"
							onClick={() => deleteProductSet(false)}
						>
							{t("cancel")}
						</button>
						<button
							className="btn btn-danger"
							onClick={handleDelete}
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("delete")}
						</button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default ProductShow;
