import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import ReactToPrint from "react-to-print";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

function Messages() {
	useLayoutEffect(() => window.scroll(0, 0), []);
	const { t, i18n } = useTranslation();
	const [messages, messagesSet] = useState([]);
	const [message, messageSet] = useState(false);
	const [isLoading, isLoadingSet] = useState(true);
	const messageRef = useRef();

	const [pages, pagesSet] = useState(0);
	const [currentPage, currentPageSet] = useState(1);

	useEffect(() => {
		isLoadingSet(true);
		messagesSet([]);
		axios
			.get("/messages", {
				headers: {
					lang: i18n.language,
				},
				params: {
					page: currentPage,
				},
			})
			.then((result) => {
				messagesSet(result.data.data);
				pagesSet(result.data.meta.last_page);
				isLoadingSet(false);
			})
			.catch(() => isLoadingSet(false));
	}, [i18n.language, currentPage]);

	return (
		<div>
			<h1 className="display-4 mb-5 text-left">{t("messages")}</h1>
			{/* <div className="form-group row">
				<label
					htmlFor="filter-select"
					className="col-xs-3 col-form-label mr-2"
				>
					{t("status")}
				</label>
				<div className="col-xs-9">
					<select className="form-control" id="filter-select">
						<option>{t("new")}</option>
						<option>{t("inProcess")}</option>
						<option>{t("done")}</option>
						<option>{t("canceled")}</option>
					</select>
				</div>
			</div> */}
			<div className="table-responsive">
				<table className="table table-hover text-left">
					<thead>
						<tr>
							<th scope="col">{t("name")}</th>
							<th scope="col">{t("email")}</th>
							<th scope="col">{t("date")}</th>
							<th scope="col" style={{ width: "150px" }}>
								{t("options")}
							</th>
						</tr>
					</thead>
					<tbody>
						{messages?.map((item) => (
							<tr
								style={{
									fontWeight:
										item.is_read == 1 ? "700" : "400",
								}}
							>
								<td>{item.name}</td>

								<td> {item.email}</td>
								<td>{item.created_at}</td>

								<td>
									<button
										className="btn btn-primary ml-auto"
										onClick={() => messageSet(item)}
									>
										{t("details")}
									</button>
								</td>
							</tr>
						))}
						{!isLoading && messages.length < 1 ? (
							<tr>
								<td colSpan="6">
									<p className="text-center mt-3 text-muted">
										{t("noMessages")}
									</p>
								</td>
							</tr>
						) : (
							""
						)}
					</tbody>
				</table>
			</div>

			{isLoading && (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-primary"
						role="status"
					></div>
				</div>
			)}

			<div className="d-flex justify-content-center">
				<Pagination
					count={pages}
					color="secondary"
					shape="rounded"
					page={currentPage}
					onChange={(e, value) => currentPageSet(value)}
					hidePrevButton
					hideNextButton
				/>
			</div>

			<Modal
				size="lg"
				show={message}
				onHide={() => messageSet(false)}
				aria-labelledby="message-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<Modal.Header>
					<Modal.Title id="message-modal">
						{t("messageFrom")} {message?.created_at}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						className={`text-left mt-3 ${
							i18n.language === "ar" ? "text__right" : ""
						}`}
						style={{
							fontSize: "20px",
							padding: "10px",
						}}
						ref={messageRef}
					>
						<h1 className="text-center mt-5 mb-5 d-none d-print-block">
							{t("message")}
						</h1>
						<strong>{t("sender")}:</strong>{" "}
						<br className="d-none d-print-block" /> {message?.name}
						<br />
						<strong>{t("email")}:</strong>{" "}
						<br className="d-none d-print-block" /> {message?.email}{" "}
						<br />
						<strong>{t("date")}:</strong>{" "}
						<br className="d-none d-print-block" /> {message?.date}{" "}
						<br />
						<hr />
						<p className="mt-5 mb-5">{message.message}</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn" onClick={() => messageSet(false)}>
						{t("close")}
					</button>

					<ReactToPrint
						trigger={() => (
							<button className="btn btn-primary">
								{t("print")}
							</button>
						)}
						content={() => messageRef.current}
					/>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default Messages;
