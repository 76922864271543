import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

function Colors() {
	const { t, i18n } = useTranslation();
	const [colors, colorsSet] = useState([]);
	const [isLoading, isLoadingSet] = useState(true);
	const [addColor, addColorSet] = useState(false);
	const [editColor, editColorSet] = useState(false);
	const [deleteColor, deleteColorSet] = useState(false);
	const [colorName, colorNameSet] = useState("");
	const [colorValue, colorValueSet] = useState("#000000");
	const [isSubmitting, isSubmittingSet] = useState(false);

	function handleAdd(e) {
		e.preventDefault();
		isSubmittingSet(true);
		axios
			.post("/colors", {
				name: colorName,
				value: colorValue,
			})
			.then(() => {
				handleClose();
				axios.get("/colors").then((result) => {
					colorsSet(result.data);
				});
			})
			.catch((err) => console.log(err.response));
	}

	function handleEdit(e) {
		e.preventDefault();
		isSubmittingSet(true);
		axios
			.put(`/colors/${editColor.id}`, {
				name: colorName,
				value: colorValue,
			})
			.then(() => {
				handleClose();
				axios.get("/colors").then((result) => {
					colorsSet(result.data);
				});
			})
			.catch((err) => console.log(err.response));
	}

	function handleClose() {
		deleteColorSet(false);
		addColorSet(false);
		editColorSet(false);
		isSubmittingSet(false);
		colorNameSet("");
		colorValueSet("#000000");
	}

	function handleDelete() {
		axios.delete(`/colors/${deleteColor.id}`).then(() => {
			handleClose();
			axios.get("/colors").then((result) => {
				colorsSet(result.data);
			});
		});
	}

	useEffect(() => {
		axios.get("/colors").then((result) => {
			isLoadingSet(false);
			colorsSet(result.data);
		});
	}, []);

	useEffect(() => {
		if (editColor) {
			colorNameSet(editColor.name);
			colorValueSet(editColor.value);
		}
	}, [editColor]);

	return (
		<div>
			<h1 className="display-4 mb-5 text-left">{t("colors")}</h1>
			<div className="d-flex align-items-center justifiy-content-between mb-5">
				<button
					className="btn btn-success"
					onClick={() => addColorSet(true)}
				>
					{t("addNewColor")}
				</button>
			</div>

			<table className="table table-hover text-left">
				<thead>
					<tr>
						<th scope="col">{t("color")}</th>
						<th scope="col">{t("name")}</th>

						<th scope="col" style={{ width: "160px" }}>
							{t("options")}
						</th>
					</tr>
				</thead>
				<tbody>
					{colors.map((color) => (
						<tr key={color.id}>
							<th scope="row">
								<div
									style={{
										width: "25px",
										height: "25px",
										backgroundColor: color.value,
									}}
								></div>
							</th>
							<td>{color.name}</td>

							<td>
								<button
									className="btn btn-primary ml-auto mr-1"
									onClick={() => editColorSet(color)}
								>
									{t("edit")}
								</button>
								<button
									className="btn btn-danger ml-auto"
									onClick={() => deleteColorSet(color)}
								>
									{t("delete")}
								</button>
							</td>
						</tr>
					))}
					{!isLoading && colors.length < 1 ? (
						<tr>
							<td colSpan="6">
								<p className="text-center mt-3 text-muted">
									{t("noColors")}
								</p>
							</td>
						</tr>
					) : (
						""
					)}
				</tbody>
			</table>
			{isLoading && (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-primary"
						role="status"
					></div>
				</div>
			)}

			{/** Add Modal */}

			<Modal
				size="lg"
				show={addColor}
				onHide={handleClose}
				aria-labelledby="delete-color-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleAdd}>
					<Modal.Header>
						<Modal.Title id="delete-color-modal">
							{t("addNewColor")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<p className="text-left">{t("name")}</p>
							<input
								className="form-control"
								required
								value={colorName}
								onChange={(e) => colorNameSet(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<p className="text-left">{t("color")}</p>
							<input
								type="color"
								className="form-control"
								required
								value={colorValue}
								onChange={(e) => colorValueSet(e.target.value)}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>

						<button
							className="btn btn-success"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("add")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Edit Modal */}

			<Modal
				size="lg"
				show={editColor}
				onHide={handleClose}
				aria-labelledby="edit-color-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleEdit}>
					<Modal.Header>
						<Modal.Title id="edit-color-modal">
							{t("edit")} {t("color")} {editColor?.name}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<p className="text-left">{t("name")}</p>
							<input
								className="form-control"
								required
								value={colorName}
								onChange={(e) => colorNameSet(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<p className="text-left">{t("color")}</p>
							<input
								type="color"
								className="form-control"
								required
								value={colorValue}
								onChange={(e) => colorValueSet(e.target.value)}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>

						<button
							className="btn btn-primary"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("edit")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Delete Modal */}

			<Modal
				size="lg"
				show={deleteColor}
				onHide={handleClose}
				aria-labelledby="delete-color-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<Modal.Header>
					<Modal.Title id="delete-color-modal">
						{t("delete")} {deleteColor?.name}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="text-left">{t("deleteColor")}</p>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn" onClick={handleClose}>
						{t("cancel")}
					</button>
					<button className="btn btn-danger" onClick={handleDelete}>
						{t("delete")}
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default Colors;
