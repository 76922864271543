import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

function Orders() {
	const { t, i18n } = useTranslation();
	const [order, orderSet] = useState(false);
	const [orderStatus, orderStatusSet] = useState("");
	const [orderDetails, orderDetailsSet] = useState({});
	const [orders, ordersSet] = useState([]);
	const [isLoading, isLoadingSet] = useState(true);
	const [isLoadingOrder, isLoadingOrderSet] = useState(false);
	const [isChangingStatus, isChangingStatusSet] = useState(false);

	const [pages, pagesSet] = useState(0);
	const [currentPage, currentPageSet] = useState(1);
	const [status, statusSet] = useState("all");

	useEffect(() => {
		isLoadingSet(true);
		ordersSet([]);
		axios
			.get("/orders/admin", {
				params: {
					page: currentPage,
					status: status === "all" ? undefined : status,
				},
			})
			.then((result) => {
				ordersSet(result.data.data);
				isLoadingSet(false);
				pagesSet(result.data.meta.last_page);
			});
	}, [currentPage, status]);

	useEffect(() => {
		currentPageSet(1);
	}, [status]);

	useEffect(() => {
		if (order) {
			isLoadingOrderSet(true);
			axios.get(`/orders/${order}`).then((result) => {
				orderDetailsSet(result.data);
				orderStatusSet(result.data.status);
				isLoadingOrderSet(false);
			});
		}
	}, [order]);

	function handleChangeStatus(e) {
		isChangingStatusSet(true);
		orderStatusSet(e.target.value);
		axios
			.put(`/orders/${orderDetails?.id}`, { status: e.target.value })
			.then(() => {
				isChangingStatusSet(false);
				isLoadingSet(true);
				ordersSet([]);
				axios
					.get("/orders/admin", {
						params: {
							page: currentPage,
							status: status === "all" ? undefined : status,
						},
					})
					.then((result) => {
						ordersSet(result.data.data);
						isLoadingSet(false);
					});
			});
	}

	function handleClose() {
		orderSet(false);
		orderDetailsSet({});
		orderStatusSet("");
		isLoadingOrderSet(false);
		isChangingStatusSet(false);
	}
	return (
		<div>
			<h1 className="display-4 mb-5 text-left">{t("orders")}</h1>
			<div className="form-group row">
				<label
					htmlFor="filter-select"
					className="col-xs-3 col-form-label mr-2"
				>
					{t("status")}
				</label>
				<div className="col-xs-9">
					<select
						className="form-control"
						id="filter-select"
						value={status}
						onChange={(e) => statusSet(e.target.value)}
					>
						<option value="all">{t("all")}</option>
						<option value="new">{t("new")}</option>
						<option value="in_process">{t("inProcess")}</option>
						<option value="done">{t("done")}</option>
						<option value="canceled">{t("canceled")}</option>
					</select>
				</div>
			</div>
			<div className="table-responsive">
				<table className="table table-hover text-left">
					<thead>
						<tr>
							<th scope="col">#</th>
							<th scope="col">{t("client")}</th>

							<th scope="col">{t("amount")}</th>
							<th scope="col">{t("date")}</th>
							<th scope="col">{t("status")}</th>
							<th scope="col" style={{ width: "150px" }}>
								{t("options")}
							</th>
						</tr>
					</thead>
					<tbody>
						{orders.map((item) => (
							<tr
								style={{
									fontWeight:
										item.is_read == 1 ? "700" : "400",
								}}
							>
								<td>{item.id}</td>
								<td>{item.user.name}</td>

								<td>
									{" "}
									{item.total} {t("sar")}{" "}
								</td>
								<td>{item.created_at.slice(0, 10)}</td>
								<td>
									{item.status === "new" && (
										<span class="badge badge-secondary">
											{t("new")}
										</span>
									)}
									{item.status === "in_process" && (
										<span class="badge badge-warning">
											{t("inProcess")}
										</span>
									)}
									{item.status === "done" && (
										<span class="badge badge-success">
											{t("done")}
										</span>
									)}
									{item.status === "canceled" && (
										<span class="badge badge-danger">
											{t("canceled")}
										</span>
									)}
								</td>
								<td>
									<button
										className="btn btn-primary ml-auto"
										onClick={() => orderSet(item.id)}
									>
										{t("details")}
									</button>
								</td>
							</tr>
						))}

						{!isLoading && orders.length < 1 ? (
							<tr>
								<td colSpan="6">
									<p className="text-center mt-3 text-muted">
										{t("noOrders")}
									</p>
								</td>
							</tr>
						) : (
							""
						)}
					</tbody>
				</table>
			</div>
			{isLoading && (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-primary"
						role="status"
					></div>
				</div>
			)}
			<div className="d-flex justify-content-center">
				<Pagination
					count={pages}
					color="secondary"
					shape="rounded"
					page={currentPage}
					onChange={(e, value) => currentPageSet(value)}
					hidePrevButton
					hideNextButton
				/>
			</div>

			<Modal
				size="lg"
				show={order}
				onHide={handleClose}
				aria-labelledby="order-modal"
				dialogClassName="modal-90w"
				animation={false}
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<Modal.Header>
					<Modal.Title id="order-modal">
						{t("order")} #{order}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{isLoadingOrder ? (
						<div className="d-flex justify-content-center mt-5 mb-5">
							<div
								className="spinner-border text-primary"
								role="status"
							></div>
						</div>
					) : (
						<>
							<div
								className="row m-1 mb-3"
								style={{ maxWidth: "400px" }}
							>
								<label
									htmlFor="filter-select"
									className="col-xs-3 col-form-label mr-2"
								>
									<strong>{t("status")}</strong>
								</label>
								<div className="col-xs-9">
									<select
										className="form-control"
										id="filter-select"
										value={orderStatus}
										disabled={isChangingStatus}
										onChange={(e) => handleChangeStatus(e)}
									>
										<option value="new">{t("new")}</option>
										<option value="in_process">
											{t("inProcess")}
										</option>
										<option value="done">
											{t("done")}
										</option>
										<option value="canceled">
											{t("canceled")}
										</option>
									</select>
								</div>
							</div>
							{isChangingStatus && (
								<div className="d-flex mt-1 mb-2">
									<div
										className="spinner-border text-primary"
										role="status"
									></div>
								</div>
							)}
							<div
								className="text-left"
								style={{ fontSize: "18px", lineHeight: "2" }}
							>
								<strong>{t("name")}:</strong>{" "}
								{orderDetails?.user?.name}
								<br />
								<strong>{t("email")}:</strong>{" "}
								{orderDetails?.user?.email}
								<br />
								<strong>{t("phone")}:</strong>{" "}
								{orderDetails?.phone} <br />
								<strong>{t("address")}:</strong>{" "}
								{orderDetails?.address} <br />
								<strong>{t("date")}:</strong>{" "}
								{orderDetails?.created_at?.slice(0, 10)} <br />
								<strong>{t("additionalNote")}:</strong>{" "}
								{orderDetails?.note} <br />
							</div>
							<div className="table-responsive mt-5">
								<table className="table text-left">
									<thead>
										<tr>
											<th scope="col">{t("product")}</th>

											<th scope="col">{t("color")}</th>
											<th scope="col">
												{t("sheenLevel")}
											</th>
											<th scope="col">{t("size")}</th>
											<th scope="col">{t("price")}</th>
											<th scope="col">{t("amount")}</th>
										</tr>
									</thead>
									<tbody>
										{orderDetails?.items?.map((item) => (
											<tr>
												<td>
													{i18n.language === "en"
														? item.product_en
														: item.product_ar}
												</td>
												<td>{item.color}</td>
												<td>
													{i18n.language === "en"
														? item.sheen_en
														: item.sheen_ar}
												</td>
												<td>{item.size}</td>

												<td>
													{" "}
													{item.price} {t("sar")}
												</td>
												<td>x{item.quantity}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</>
					)}
				</Modal.Body>
				<Modal.Footer>
					<button className="btn" onClick={handleClose}>
						{t("close")}
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default Orders;
