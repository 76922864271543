import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import easy_to_apply_png from "../assets/product-icons/easy-to-apply.png";
import eco_friendly_png from "../assets/product-icons/eco-friendly.png";
import excellent_adhesion_png from "../assets/product-icons/excellent-adhesion.png";
import excellent_washability_png from "../assets/product-icons/excellent-washability.png";
import high_durability_png from "../assets/product-icons/high-durability.png";
import multiple_colors_png from "../assets/product-icons/multiple-colors.png";
import multiple_designs_png from "../assets/product-icons/multiple-designs.png";
import odorless_png from "../assets/product-icons/odorless.png";
import resistance_to_efflorescence_png from "../assets/product-icons/resistance-to-efflorescence.png";
import stain_resistant_png from "../assets/product-icons/stain-resistant.png";

function ProductEdit() {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const { productId } = useParams();
	const [categories, categoriesSet] = useState([]);

	const [isLoading, isLoadingSet] = useState(true);

	const [isSubmitting, isSubmittingSet] = useState(false);
	const [productNameEn, productNameEnSet] = useState("");
	const [productNameAr, productNameArSet] = useState("");
	const [productFile, productFileSet] = useState();
	const [productSizes, productSizesSet] = useState([
		{ size_id: "", price: "", sheen_id: "" },
	]);
	const [productRate, productRateSet] = useState("0");
	const [productDescriptionEn, productDescriptionEnSet] = useState("");
	const [productDescriptionAr, productDescriptionArSet] = useState("");
	const [productShortDescriptionEn, productShortDescriptionEnSet] = useState(
		""
	);
	const [productShortDescriptionAr, productShortDescriptionArSet] = useState(
		""
	);
	const [productCategory, productCategorySet] = useState("");
	const [productImage, productImageSet] = useState(false);
	const [productImageUrl, productImageUrlSet] = useState("");
	const [productFeatures, productFeaturesSet] = useState([]);
	const [errors, errorsSet] = useState([]);

	// Icons
	const [easy_to_apply, easy_to_apply_set] = useState(false);
	const [eco_friendly, eco_friendly_set] = useState(false);
	const [excellent_adhesion, excellent_adhesion_set] = useState(false);
	const [excellent_washability, excellent_washability_set] = useState(false);
	const [high_durability, high_durability_set] = useState(false);
	const [multiple_designs, multiple_designs_set] = useState(false);
	const [odorless, odorless_set] = useState(false);
	const [multiple_colors, multiple_colors_set] = useState(false);
	const [
		resistance_to_efflorescence,
		resistance_to_efflorescence_set,
	] = useState(false);
	const [stain_resistant, stain_resistant_set] = useState(false);

	function handleSubmit(e) {
		e.preventDefault();
		isSubmittingSet(true);
		let product_sizes = {};
		for (let i = 0; i < productSizes.length; i++) {
			product_sizes[productSizes[i].size_id] = {
				price: productSizes[i].price,
			};
		}
		console.log(product_sizes);
		let formData = new FormData();
		formData.append("name_ar", productNameAr);
		formData.append("name_en", productNameEn);
		formData.append("short_description_ar", productShortDescriptionAr);
		formData.append("short_description_en", productShortDescriptionEn);
		formData.append("description_ar", productDescriptionAr);
		formData.append("description_en", productDescriptionEn);
		formData.append("category_id", productCategory);
		if (productImage) {
			formData.append("image", productImage);
		}
		if (productFile) {
			formData.append("file", productFile);
		}
		formData.append("rate", productRate);
		formData.append("prices", JSON.stringify(productSizes));
		if (productFeatures.length > 0) {
			formData.append("features", JSON.stringify(productFeatures));
		}

		//Icons
		formData.append("easy_to_apply", easy_to_apply ? 1 : 0);
		formData.append("eco_friendly", eco_friendly ? 1 : 0);
		formData.append("excellent_adhesion", excellent_adhesion ? 1 : 0);
		formData.append("excellent_washability", excellent_washability ? 1 : 0);
		formData.append("high_durability", high_durability ? 1 : 0);
		formData.append("multiple_colors", multiple_colors ? 1 : 0);
		formData.append("multiple_designs", multiple_designs ? 1 : 0);
		formData.append("odorless", odorless ? 1 : 0);
		formData.append(
			"resistance_to_efflorescence",
			resistance_to_efflorescence ? 1 : 0
		);
		formData.append("stain_resistant", stain_resistant ? 1 : 0);

		axios
			.post(`/products/${productId}`, formData)
			.then((result) => {
				isSubmittingSet(false);
				history.push(`/products/${result.data.data.id}`);
			})
			.catch((err) => {
				isSubmittingSet(false);
				console.log(err.response);
				if (err.response.data.errors) {
					errorsSet(err.response.data.errors);
				}
			});
	}

	useEffect(() => {
		categoriesSet([]);
		axios
			.get("/categories", {
				headers: {
					lang: i18n.language,
				},
			})
			.then((result) => {
				categoriesSet(result.data.data);
			});
	}, [i18n.language]);

	useEffect(() => {
		axios.get(`/products/${productId}/all`).then((result) => {
			isLoadingSet(false);
			productCategorySet(result.data.data.category.id);
			productNameArSet(result.data.data.name_ar);
			productNameEnSet(result.data.data.name_en);
			productRateSet(result.data.data.rate);

			// console.log(
			// 	result.data.data.sizes.map((size) => ({
			// 		id: size.id,
			// 		size_id: size.size_id,
			// 		price: size.price,
			// 	}))
			// );
			productShortDescriptionArSet(result.data.data.short_description_ar);
			productShortDescriptionEnSet(result.data.data.short_description_en);
			productDescriptionEnSet(result.data.data.description_en);
			productDescriptionArSet(result.data.data.description_ar);
			productImageUrlSet(result.data.data.image);
			productFeaturesSet(result.data.data.features);
			easy_to_apply_set(Boolean(Number(result.data.data.easy_to_apply)));
			eco_friendly_set(Boolean(Number(result.data.data.eco_friendly)));
			excellent_adhesion_set(
				Boolean(Number(result.data.data.excellent_adhesion))
			);
			excellent_washability_set(
				Boolean(Number(result.data.data.excellent_washability))
			);
			high_durability_set(
				Boolean(Number(result.data.data.high_durability))
			);
			multiple_colors_set(
				Boolean(Number(result.data.data.multiple_colors))
			);
			multiple_designs_set(
				Boolean(Number(result.data.data.multiple_designs))
			);
			odorless_set(Boolean(Number(result.data.data.odorless)));
			resistance_to_efflorescence_set(
				Boolean(Number(result.data.data.resistance_to_efflorescence))
			);
			stain_resistant_set(
				Boolean(Number(result.data.data.stain_resistant))
			);
		});
	}, []);

	// features functions
	function addFeature(e) {
		e.preventDefault();
		productFeaturesSet((oldState) => [
			...oldState,
			{ name_en: "", name_ar: "", value_en: "", value_ar: "" },
		]);
	}

	function deleteFeature(e, idx) {
		e.preventDefault();
		productFeaturesSet((oldState) => [
			...oldState.slice(0, idx),
			...oldState.slice(idx + 1),
		]);
	}

	function editFeatureNameAr(e, idx) {
		productFeaturesSet((oldState) => [
			...oldState.slice(0, idx),
			{ ...oldState[idx], name_ar: e.target.value },
			...oldState.slice(idx + 1),
		]);
	}

	function editFeatureNameEn(e, idx) {
		productFeaturesSet((oldState) => [
			...oldState.slice(0, idx),
			{ ...oldState[idx], name_en: e.target.value },
			...oldState.slice(idx + 1),
		]);
	}

	function editFeatureValueAr(e, idx) {
		productFeaturesSet((oldState) => [
			...oldState.slice(0, idx),
			{ ...oldState[idx], value_ar: e.target.value },
			...oldState.slice(idx + 1),
		]);
	}

	function editFeatureValueEn(e, idx) {
		productFeaturesSet((oldState) => [
			...oldState.slice(0, idx),
			{ ...oldState[idx], value_en: e.target.value },
			...oldState.slice(idx + 1),
		]);
	}

	if (isLoading) {
		return (
			<div className="d-flex justify-content-center mt-5">
				<div
					className="spinner-border text-primary"
					role="status"
				></div>
			</div>
		);
	} else {
		return (
			<div>
				<h1 className="display-4 mb-5 text-left">{t("editProduct")}</h1>
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<p className="text-left"> {t("category")} </p>
						<select
							className="form-control"
							required
							value={productCategory}
							onChange={(e) => productCategorySet(e.target.value)}
						>
							<option></option>
							{categories.map((category) => (
								<option key={category.id} value={category.id}>
									{category.name}
								</option>
							))}
						</select>
					</div>

					<div className="form-group">
						<p className="text-left"> اسم المنتج </p>
						<input
							className="form-control"
							required
							value={productNameAr}
							onChange={(e) => productNameArSet(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<p className="text-left"> Product Name </p>
						<input
							className="form-control"
							required
							value={productNameEn}
							onChange={(e) => productNameEnSet(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<p className="text-left"> {t("rate")} </p>
						<input
							className="form-control"
							required
							type="number"
							step="1"
							min="0"
							max="5"
							value={productRate}
							onChange={(e) => productRateSet(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<p className="text-left"> الوصف القصير </p>
						<input
							className="form-control"
							required
							value={productShortDescriptionAr}
							onChange={(e) =>
								productShortDescriptionArSet(e.target.value)
							}
						/>
					</div>
					<div className="form-group">
						<p className="text-left"> Short Description </p>
						<input
							className="form-control"
							required
							value={productShortDescriptionEn}
							onChange={(e) =>
								productShortDescriptionEnSet(e.target.value)
							}
						/>
					</div>
					<div className="form-group">
						<p className="text-left"> الوصف </p>
						<textarea
							className="form-control"
							required
							rows={3}
							value={productDescriptionAr}
							onChange={(e) =>
								productDescriptionArSet(e.target.value)
							}
						></textarea>
					</div>
					<div className="form-group">
						<p className="text-left"> Description </p>
						<textarea
							className="form-control"
							required
							rows={3}
							value={productDescriptionEn}
							onChange={(e) =>
								productDescriptionEnSet(e.target.value)
							}
						></textarea>
					</div>
					<div className="form-group">
						<p className="text-left"> {t("changeImage")}</p>
						<input
							className="form-control p-1"
							type="file"
							accept="image/*"
							onChange={(e) => productImageSet(e.target.files[0])}
						/>
						{errors["image"] && (
							<p className="text-danger">{errors["image"]}</p>
						)}
					</div>

					<div className="form-group">
						<p className="text-left"> {t("file")}</p>
						<input
							className="form-control p-1"
							type="file"
							onChange={(e) => productFileSet(e.target.files[0])}
						/>
					</div>

					{/* <h3 className="text-left mt-5 mb-5">
						{t("sizesAndPrices")}
					</h3>
					<button className="btn btn-danger mb-3" onClick={addSize}>
						{t("addSize")}
					</button>

					<table className="table">
						<thead>
							<tr>
								<th className="text-left">{t("sheenLevel")}</th>
								<th className="text-left">{t("size")}</th>
								<th className="text-left">{t("price")}</th>
								<th className="text-center">{t("delete")}</th>
							</tr>
						</thead>
						<tbody>
							{productSizes.map((size, idx) => (
								<tr>
									<td>
										<select
											className="form-control"
											required
											value={productSizes[idx].sheen_id}
											onChange={(e) => editSheen(e, idx)}
										>
											<option></option>
											{sheens.map((sheen) => (
												<option
													key={sheen.id}
													value={sheen.id}
												>
													{
														sheen[
															`name_${i18n.language}`
														]
													}
												</option>
											))}
										</select>
									</td>
									<td>
										<select
											className="form-control"
											required
											value={productSizes[idx].size_id}
											onChange={(e) => editSize(e, idx)}
										>
											<option></option>
											{sizes.map((size) => (
												<option
													key={size.id}
													value={size.id}
												>
													{size.name}
												</option>
											))}
										</select>
									</td>
									<td>
										<input
											required
											className="form-control"
											value={productSizes[idx].price}
											type="number"
											onChange={(e) => editPrice(e, idx)}
										/>
									</td>
									<td>
										{productSizes.length > 1 && (
											<button
												className="btn btn-danger mb-3"
												onClick={(e) =>
													deleteSize(e, idx)
												}
											>
												{t("delete")}
											</button>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table> */}
					<h3 className="text-left mt-5 mb-5">{t("features")}</h3>

					{productFeatures.map((feature, idx) => (
						<div key={idx}>
							<div className="row mb-3">
								<div className="col-md-3 col-xs-12">
									<p className="text-left"> الاسم </p>
									<input
										className="form-control"
										required
										value={productFeatures[idx].name_ar}
										onChange={(e) =>
											editFeatureNameAr(e, idx)
										}
									/>
								</div>
								<div className="col-md-9 col-xs-12">
									<p className="text-left"> القيمة</p>
									<textarea
										className="form-control"
										required
										rows={3}
										value={productFeatures[idx].value_ar}
										onChange={(e) =>
											editFeatureValueAr(e, idx)
										}
									></textarea>
								</div>
							</div>
							<div className="row mb-5">
								<div className="col-md-3 col-xs-12">
									<p className="text-left"> Name </p>
									<input
										className="form-control"
										required
										value={productFeatures[idx].name_en}
										onChange={(e) =>
											editFeatureNameEn(e, idx)
										}
									/>
								</div>
								<div className="col-md-9 col-xs-12">
									<p className="text-left"> Value </p>
									<textarea
										className="form-control"
										required
										rows={3}
										value={productFeatures[idx].value_en}
										onChange={(e) =>
											editFeatureValueEn(e, idx)
										}
									></textarea>
								</div>
							</div>
							<div className="d-flex align-items-center justifiy-content-between mb-5 mt-5">
								<button
									className="btn btn-danger"
									onClick={(e) => deleteFeature(e, idx)}
								>
									{t("delete")}
								</button>
							</div>
							<hr className="mb-5" />
						</div>
					))}
					<div className="d-flex align-items-center justifiy-content-between mb-5">
						<button
							className="btn btn-primary"
							onClick={addFeature}
						>
							{t("addFeature")}
						</button>
						<hr />
					</div>
					{/** Icons */}
					<h3 className="text-left mt-5 mb-5">{t("icons")}</h3>
					<div className="form-check mb-3 d-flex align-items-center">
						<input
							className="form-check-input"
							type="checkbox"
							checked={easy_to_apply}
							onChange={(e) =>
								easy_to_apply_set(e.target.checked)
							}
							id="easy_to_apply"
						/>

						<label
							className="form-check-label ml-2 d-flex align-items-center"
							htmlFor="easy_to_apply"
						>
							<img src={easy_to_apply_png} alt="" />

							<p className="m-2">{t("easy_to_apply")}</p>
						</label>
					</div>

					<div className="form-check mb-3 d-flex align-items-center">
						<input
							className="form-check-input"
							type="checkbox"
							checked={eco_friendly}
							onChange={(e) => eco_friendly_set(e.target.checked)}
							id="eco_friendly"
						/>

						<label
							className="form-check-label ml-2 d-flex align-items-center"
							htmlFor="eco_friendly"
						>
							<img src={eco_friendly_png} alt="" />

							<p className="m-2">{t("eco_friendly")}</p>
						</label>
					</div>

					<div className="form-check mb-3 d-flex align-items-center">
						<input
							className="form-check-input"
							type="checkbox"
							checked={excellent_adhesion}
							onChange={(e) =>
								excellent_adhesion_set(e.target.checked)
							}
							id="excellent_adhesion"
						/>

						<label
							className="form-check-label ml-2 d-flex align-items-center"
							htmlFor="excellent_adhesion"
						>
							<img src={excellent_adhesion_png} alt="" />

							<p className="m-2">{t("excellent_adhesion")}</p>
						</label>
					</div>

					<div className="form-check mb-3 d-flex align-items-center">
						<input
							className="form-check-input"
							type="checkbox"
							checked={excellent_washability}
							onChange={(e) =>
								excellent_washability_set(e.target.checked)
							}
							id="excellent_washability"
						/>

						<label
							className="form-check-label ml-2 d-flex align-items-center"
							htmlFor="excellent_washability"
						>
							<img src={excellent_washability_png} alt="" />

							<p className="m-2">{t("excellent_washability")}</p>
						</label>
					</div>

					<div className="form-check mb-3 d-flex align-items-center">
						<input
							className="form-check-input"
							type="checkbox"
							checked={high_durability}
							onChange={(e) =>
								high_durability_set(e.target.checked)
							}
							id="high_durability"
						/>

						<label
							className="form-check-label ml-2 d-flex align-items-center"
							htmlFor="high_durability"
						>
							<img src={high_durability_png} alt="" />

							<p className="m-2">{t("high_durability")}</p>
						</label>
					</div>

					<div className="form-check mb-3 d-flex align-items-center">
						<input
							className="form-check-input"
							type="checkbox"
							checked={multiple_colors}
							onChange={(e) =>
								multiple_colors_set(e.target.checked)
							}
							id="multiple_colors"
						/>

						<label
							className="form-check-label ml-2 d-flex align-items-center"
							htmlFor="multiple_colors"
						>
							<img src={multiple_colors_png} alt="" />

							<p className="m-2">{t("multiple_colors")}</p>
						</label>
					</div>

					<div className="form-check mb-3 d-flex align-items-center">
						<input
							className="form-check-input"
							type="checkbox"
							checked={multiple_designs}
							onChange={(e) =>
								multiple_designs_set(e.target.checked)
							}
							id="multiple_designs"
						/>

						<label
							className="form-check-label ml-2 d-flex align-items-center"
							htmlFor="multiple_designs"
						>
							<img src={multiple_designs_png} alt="" />

							<p className="m-2">{t("multiple_designs")}</p>
						</label>
					</div>

					<div className="form-check mb-3 d-flex align-items-center">
						<input
							className="form-check-input"
							type="checkbox"
							checked={odorless}
							onChange={(e) => odorless_set(e.target.checked)}
							id="odorless"
						/>

						<label
							className="form-check-label ml-2 d-flex align-items-center"
							htmlFor="odorless"
						>
							<img src={odorless_png} alt="" />

							<p className="m-2">{t("odorless")}</p>
						</label>
					</div>

					<div className="form-check mb-3 d-flex align-items-center">
						<input
							className="form-check-input"
							type="checkbox"
							checked={resistance_to_efflorescence}
							onChange={(e) =>
								resistance_to_efflorescence_set(
									e.target.checked
								)
							}
							id="resistance_to_efflorescence"
						/>

						<label
							className="form-check-label ml-2 d-flex align-items-center"
							htmlFor="resistance_to_efflorescence"
						>
							<img src={resistance_to_efflorescence_png} alt="" />

							<p className="m-2">
								{t("resistance_to_efflorescence")}
							</p>
						</label>
					</div>

					<div className="form-check mb-3 d-flex align-items-center">
						<input
							className="form-check-input"
							type="checkbox"
							checked={stain_resistant}
							onChange={(e) =>
								stain_resistant_set(e.target.checked)
							}
							id="stain_resistant"
						/>

						<label
							className="form-check-label ml-2 d-flex align-items-center"
							htmlFor="stain_resistant"
						>
							<img src={stain_resistant_png} alt="" />

							<p className="m-2">{t("stain_resistant")}</p>
						</label>
					</div>
					{/* <h3 className="text-left mt-5 mb-3">{t("colors")}</h3>
					<div className="mt-5 mb-5"></div>
					<div className="d-flex align-items-center justifiy-content-between mb-5">
						<button className="btn btn-info" onClick={addColors}>
							{t("addColors")}
						</button>
					</div> */}
					<hr className="mt-5" />
					<div className="d-flex align-items-center justifiy-content-between mb-5 mt-5">
						<button
							className="btn btn-warning"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("editProduct")}
						</button>
					</div>
				</form>

				{/** Colors Modal */}
				{/* <Modal
					size="sm"
					show={addColorsModal}
					onHide={() => addColorsModalSet(false)}
					aria-labelledby="delete-category-modal"
					className={i18n.language === "en" ? "" : "rtl"}
				>
					<Modal.Header>
						<Modal.Title id="delete-category-modal">
							{t("colors")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p className="text-left">
							{colors.length > 0 ? (
								colors.map((color, idx) => (
									<div
										className="form-check mb-3 d-flex align-items-center"
										key={idx}
									>
										<input
											className="form-check-input"
											type="checkbox"
											value={color.id}
											checked={productColors.includes(
												color.id
											)}
											onChange={() =>
												handleColorChange(color.id)
											}
											id={`color-check-${idx}`}
										/>

										<label
											className="form-check-label ml-2 d-flex align-items-center"
											htmlFor={`color-check-${idx}`}
										>
											<div
												style={{
													width: "50px",
													height: "50px",
													borderRadius: "5px",
													backgroundColor:
														color.value,
												}}
											></div>
											<p className="m-2">{color.name}</p>
										</label>
									</div>
								))
							) : (
								<p className="text-muted text-center mt-5 mb-5">
									{t("noColors")}
								</p>
							)}
						</p>
					</Modal.Body>
					<Modal.Footer>
						<button
							className="btn"
							onClick={() => addColorsModalSet(false)}
						>
							{t("done")}
						</button>
					</Modal.Footer>
				</Modal> */}
			</div>
		);
	}
}

export default ProductEdit;
