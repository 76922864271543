import React, { useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

function Settings() {
	useLayoutEffect(() => window.scroll(0, 0), []);
	const { t, i18n } = useTranslation();
	const [sliderImages, sliderImagesSet] = useState([]);
	const [companyFeatures, companyFeaturesSet] = useState([]);
	const [addSliderImage, addSliderImageSet] = useState(false);
	const [editSliderImage, editSliderImageSet] = useState(false);
	const [deleteSliderImage, deleteSliderImageSet] = useState(false);
	const [isSubmitting, isSubmittingSet] = useState(false);
	const [errors, errorsSet] = useState([]);

	const [addCompanyFeature, addCompanyFeatureSet] = useState(false);
	const [editCompanyFeature, editCompanyFeatureSet] = useState(false);
	const [deleteCompanyFeature, deleteCompanyFeatureSet] = useState(false);

	const [sliderImagesTitleEn, sliderImagesTitleEnSet] = useState("");
	const [sliderImagesTitleAr, sliderImagesTitleArSet] = useState("");
	const [sliderImagesDescriptionEn, sliderImagesDescriptionEnSet] = useState(
		""
	);
	const [sliderImagesDescriptionAr, sliderImagesDescriptionArSet] = useState(
		""
	);
	const [sliderImagesLinkTitleEn, sliderImagesLinkTitleEnSet] = useState("");
	const [sliderImagesLinkTitleAr, sliderImagesLinkTitleArSet] = useState("");
	const [sliderImagesLinkUrl, sliderImagesLinkUrlSet] = useState("");
	const [sliderImagesFile, sliderImagesFileSet] = useState(null);

	const [companyFeatureTitleEn, companyFeatureTitleEnSet] = useState("");
	const [companyFeatureTitleAr, companyFeatureTitleArSet] = useState("");
	const [
		companyFeatureDescriptionEn,
		companyFeatureDescriptionEnSet,
	] = useState("");
	const [
		companyFeatureDescriptionAr,
		companyFeatureDescriptionArSet,
	] = useState("");
	const [companyFeatureFile, companyFeatureFileSet] = useState(null);

	const [facebook, facebookSet] = useState("");
	const [twitter, twitterSet] = useState("");
	const [youtube, youtubeSet] = useState("");
	const [instagram, instagramSet] = useState("");
	const [mainPhone, mainPhoneSet] = useState("");
	const [mainAddressEn, mainAddressEnSet] = useState("");
	const [mainAddressAr, mainAddressArSet] = useState("");
	const [companyEmail, companyEmailSet] = useState("");
	const [aboutEn, aboutEnSet] = useState("");
	const [aboutAr, aboutArSet] = useState("");
	const [aboutImage, aboutImageSet] = useState();
	const [aboutImageUrl, aboutImageUrlSet] = useState();
	const [isSubmittingInfo, isSubmittingInfoSet] = useState(false);

	function handleClose() {
		addSliderImageSet(false);
		editSliderImageSet(false);
		deleteSliderImageSet(false);
		isSubmittingSet(false);
		errorsSet([]);

		addCompanyFeatureSet(false);
		editCompanyFeatureSet(false);
		deleteCompanyFeatureSet(false);

		sliderImagesTitleEnSet("");
		sliderImagesTitleArSet("");
		sliderImagesDescriptionEnSet("");
		sliderImagesDescriptionArSet("");
		sliderImagesLinkTitleEnSet("");
		sliderImagesLinkTitleArSet("");
		sliderImagesLinkUrlSet("");
		sliderImagesFileSet(null);

		companyFeatureTitleEnSet("");
		companyFeatureTitleArSet("");
		companyFeatureDescriptionEnSet("");
		companyFeatureDescriptionArSet("");
		companyFeatureFileSet(null);
	}

	useEffect(() => {
		axios.get("/slider-images/admin").then((result) => {
			sliderImagesSet(result.data);
		});

		axios.get("/company-features/admin").then((result) => {
			companyFeaturesSet(result.data);
		});

		axios.get("/info").then((result) => {
			facebookSet(result.data.data.facebook);
			twitterSet(result.data.data.twitter);
			youtubeSet(result.data.data.youtube);
			instagramSet(result.data.data.instagram);
			mainPhoneSet(result.data.data.main_phone);
			mainAddressEnSet(result.data.data.main_address_en);
			mainAddressArSet(result.data.data.main_address_ar);
			aboutEnSet(result.data.data.about_en);
			aboutArSet(result.data.data.about_ar);
			companyEmailSet(result.data.data.company_email);
			aboutImageUrlSet(result.data.data.about_image);
		});
	}, []);

	useEffect(() => {
		if (editSliderImage) {
			sliderImagesTitleEnSet(editSliderImage.title_en);
			sliderImagesTitleArSet(editSliderImage.title_ar);
			sliderImagesDescriptionEnSet(editSliderImage.description_en);
			sliderImagesDescriptionArSet(editSliderImage.description_ar);
			sliderImagesLinkTitleEnSet(editSliderImage.link_title_en);
			sliderImagesLinkTitleArSet(editSliderImage.link_title_ar);
			sliderImagesLinkUrlSet(editSliderImage.link_url);
		}
	}, [editSliderImage]);

	useEffect(() => {
		if (editCompanyFeature) {
			companyFeatureTitleEnSet(editCompanyFeature.title_en);
			companyFeatureTitleArSet(editCompanyFeature.title_ar);
			companyFeatureDescriptionEnSet(editCompanyFeature.description_en);
			companyFeatureDescriptionArSet(editCompanyFeature.description_ar);
		}
	}, [editCompanyFeature]);

	function handleAddSlideImage(e) {
		e.preventDefault();
		errorsSet([]);
		isSubmittingSet(true);
		let formData = new FormData();
		formData.append("image", sliderImagesFile);
		formData.append("title_en", sliderImagesTitleEn);
		formData.append("title_ar", sliderImagesTitleAr);
		formData.append("description_en", sliderImagesDescriptionEn);
		formData.append("description_ar", sliderImagesDescriptionAr);
		formData.append("link_title_en", sliderImagesLinkTitleEn);
		formData.append("link_title_ar", sliderImagesLinkTitleAr);
		formData.append("link_url", sliderImagesLinkUrl);
		axios
			.post("/slider-images", formData, {
				headers: {
					lang: i18n.language,
				},
			})
			.then(() => {
				handleClose();
				axios.get("/slider-images/admin").then((result) => {
					sliderImagesSet(result.data);
				});
			})
			.catch((err) => {
				isSubmittingSet(false);
				if (err.response.data.errors) {
					errorsSet(err.response.data.errors);
				}
			});
	}

	function handleEditSlideImage(e) {
		e.preventDefault();
		errorsSet([]);
		isSubmittingSet(true);
		let formData = new FormData();
		if (sliderImagesFile) {
			formData.append("image", sliderImagesFile);
		}
		formData.append("title_en", sliderImagesTitleEn);
		formData.append("title_ar", sliderImagesTitleAr);
		formData.append("description_en", sliderImagesDescriptionEn);
		formData.append("description_ar", sliderImagesDescriptionAr);
		formData.append("link_title_en", sliderImagesLinkTitleEn);
		formData.append("link_title_ar", sliderImagesLinkTitleAr);
		formData.append("link_url", sliderImagesLinkUrl);
		axios
			.post(`/slider-images/${editSliderImage.id}`, formData, {
				headers: {
					lang: i18n.language,
				},
			})
			.then(() => {
				handleClose();
				axios.get("/slider-images/admin").then((result) => {
					sliderImagesSet(result.data);
				});
			})
			.catch((err) => {
				isSubmittingSet(false);
				if (err.response.data.errors) {
					errorsSet(err.response.data.errors);
				}
			});
	}

	function handleDeleteSlideImage() {
		isSubmittingSet(true);
		axios.delete(`/slider-images/${deleteSliderImage.id}`).then(() => {
			handleClose();
			axios.get("/slider-images/admin").then((result) => {
				sliderImagesSet(result.data);
			});
		});
	}

	function handleAddCompanyFeature(e) {
		e.preventDefault();
		errorsSet([]);
		isSubmittingSet(true);
		let formData = new FormData();
		formData.append("image", companyFeatureFile);
		formData.append("title_en", companyFeatureTitleEn);
		formData.append("title_ar", companyFeatureTitleAr);
		formData.append("description_en", companyFeatureDescriptionEn);
		formData.append("description_ar", companyFeatureDescriptionAr);
		axios
			.post("/company-features", formData, {
				headers: {
					lang: i18n.language,
				},
			})
			.then(() => {
				handleClose();
				axios.get("/company-features/admin").then((result) => {
					companyFeaturesSet(result.data);
				});
			})
			.catch((err) => {
				isSubmittingSet(false);
				if (err.response.data.errors) {
					errorsSet(err.response.data.errors);
				}
			});
	}

	function handleEditCompanyFeature(e) {
		e.preventDefault();
		errorsSet([]);
		isSubmittingSet(true);
		let formData = new FormData();
		if (companyFeatureFile) {
			formData.append("image", companyFeatureFile);
		}

		formData.append("title_en", companyFeatureTitleEn);
		formData.append("title_ar", companyFeatureTitleAr);
		formData.append("description_en", companyFeatureDescriptionEn);
		formData.append("description_ar", companyFeatureDescriptionAr);
		axios
			.post(`/company-features/${editCompanyFeature.id}`, formData, {
				headers: {
					lang: i18n.language,
				},
			})
			.then(() => {
				handleClose();
				axios.get("/company-features/admin").then((result) => {
					companyFeaturesSet(result.data);
				});
			})
			.catch((err) => {
				isSubmittingSet(false);
				if (err.response.data.errors) {
					errorsSet(err.response.data.errors);
				}
			});
	}

	function handleDeleteCompanyFeature() {
		isSubmittingSet(true);
		axios
			.delete(`/company-features/${deleteCompanyFeature.id}`)
			.then(() => {
				handleClose();
				axios.get("/company-features/admin").then((result) => {
					companyFeaturesSet(result.data);
				});
			});
	}

	function saveInfo(e) {
		e.preventDefault();
		isSubmittingInfoSet(true);
		let formData = new FormData();
		formData.append("facebook", facebook);
		formData.append("twitter", twitter);
		formData.append("youtube", youtube);
		formData.append("instagram", instagram);
		formData.append("about_en", aboutEn);
		formData.append("about_ar", aboutAr);
		formData.append("main_address_en", mainAddressEn);
		formData.append("main_address_ar", mainAddressAr);
		formData.append("main_phone", mainPhone);
		formData.append("company_email", companyEmail);
		formData.append("about_image", aboutImage);
		axios.post("/info", formData).then((result) => {
			console.log(result);
			isSubmittingInfoSet(false);
		});
	}

	return (
		<div>
			<h1 className="display-4 mb-5 text-left">{t("settings")}</h1>
			<div className="card mb-5">
				<form onSubmit={saveInfo}>
					<div className="card-body">
						<h3 className="card-title text-left font-weight-normal">
							{t("information")}
						</h3>
						<div className="form-group">
							<p className="text-left"> Facebook URL </p>
							<input
								className="form-control"
								required
								type="url"
								value={facebook}
								onChange={(e) => facebookSet(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<p className="text-left"> Twitter URL </p>
							<input
								className="form-control"
								required
								type="url"
								value={twitter}
								onChange={(e) => twitterSet(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<p className="text-left"> Youtube URL </p>
							<input
								className="form-control"
								required
								type="url"
								value={youtube}
								onChange={(e) => youtubeSet(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<p className="text-left"> Instagram URL </p>
							<input
								className="form-control"
								required
								type="url"
								value={instagram}
								onChange={(e) => instagramSet(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<p className="text-left"> {t("phone")} </p>
							<input
								className="form-control"
								required
								value={mainPhone}
								onChange={(e) => mainPhoneSet(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<p className="text-left"> Address </p>
							<input
								className="form-control"
								required
								value={mainAddressEn}
								onChange={(e) =>
									mainAddressEnSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> العنوان </p>
							<input
								className="form-control"
								required
								value={mainAddressAr}
								onChange={(e) =>
									mainAddressArSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> {t("email")} </p>
							<input
								className="form-control"
								required
								type="email"
								value={companyEmail}
								onChange={(e) =>
									companyEmailSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> About Company </p>

							<textarea
								className="form-control"
								required
								rows="5"
								value={aboutEn}
								onChange={(e) => aboutEnSet(e.target.value)}
							></textarea>
						</div>

						<div className="form-group">
							<p className="text-left"> عن الشركة </p>
							<textarea
								className="form-control"
								required
								rows="5"
								value={aboutAr}
								onChange={(e) => aboutArSet(e.target.value)}
							></textarea>
						</div>

						{/* <img
							src={aboutImageUrl}
							className="ml-auto mr-auto d-block"
							style={{ width: "250px" }}
							alt=""
						/> */}
						<div className="form-group">
							<p className="text-left"> {t("image")}</p>
							<input
								className="form-control p-1"
								type="file"
								accept="image/*"
								required
								onChange={(e) => {
									aboutImageSet(e.target.files[0]);
								}}
							/>
						</div>

						<button
							className="btn btn-success"
							type="submit"
							disabled={isSubmittingInfo}
						>
							{isSubmittingInfo && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("save")}
						</button>
					</div>
				</form>
			</div>
			<div className="card mb-5">
				<div className="card-body">
					<h3 className="card-title text-left font-weight-normal">
						{t("sliderImages")}
					</h3>
					<div className="d-flex align-items-center justifiy-content-between mb-5">
						<button
							className="btn btn-success"
							onClick={() => addSliderImageSet(true)}
						>
							{t("add")}
						</button>
					</div>
					<table className="table table-hover text-left">
						<thead>
							<tr>
								<th scope="col">{t("title")}</th>

								<th scope="col" style={{ width: "160px" }}>
									{t("options")}
								</th>
							</tr>
						</thead>
						<tbody>
							{sliderImages.map((item) => (
								<tr>
									<td>{item["title_" + i18n.language]}</td>
									<td>
										<button
											className="btn btn-primary ml-auto mr-1"
											onClick={() =>
												editSliderImageSet(item)
											}
										>
											{t("edit")}
										</button>
										<button
											className="btn btn-danger ml-auto"
											onClick={() =>
												deleteSliderImageSet(item)
											}
										>
											{t("delete")}
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<h3 className="card-title text-left font-weight-normal">
						{t("companyFeatures")}
					</h3>
					<div className="d-flex align-items-center justifiy-content-between mb-5">
						<button
							className="btn btn-success"
							onClick={() => addCompanyFeatureSet(true)}
						>
							{t("add")}
						</button>
					</div>

					<table className="table table-hover text-left">
						<thead>
							<tr>
								<th scope="col">{t("title")}</th>

								<th scope="col" style={{ width: "160px" }}>
									{t("options")}
								</th>
							</tr>
						</thead>
						<tbody>
							{companyFeatures.map((item) => (
								<tr>
									<td>{item["title_" + i18n.language]}</td>
									<td>
										<button
											className="btn btn-primary ml-auto mr-1"
											onClick={() =>
												editCompanyFeatureSet(item)
											}
										>
											{t("edit")}
										</button>
										<button
											className="btn btn-danger ml-auto"
											onClick={() =>
												deleteCompanyFeatureSet(item)
											}
										>
											{t("delete")}
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{/** Add Slider Image */}
			<Modal
				size="lg"
				show={addSliderImage}
				onHide={handleClose}
				aria-labelledby="add-branch-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleAddSlideImage}>
					<Modal.Header>
						<Modal.Title id="add-branch-modal">
							{t("addNewImage")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<p className="text-left"> العنوان </p>
							<input
								className="form-control"
								required
								value={sliderImagesTitleAr}
								onChange={(e) =>
									sliderImagesTitleArSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Title </p>
							<input
								className="form-control"
								required
								value={sliderImagesTitleEn}
								onChange={(e) =>
									sliderImagesTitleEnSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> الوصف </p>
							<input
								className="form-control"
								required
								value={sliderImagesDescriptionAr}
								onChange={(e) =>
									sliderImagesDescriptionArSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Description </p>
							<input
								className="form-control"
								required
								value={sliderImagesDescriptionEn}
								onChange={(e) =>
									sliderImagesDescriptionEnSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> النص الرابط </p>
							<input
								className="form-control"
								required
								value={sliderImagesLinkTitleAr}
								onChange={(e) =>
									sliderImagesLinkTitleArSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Link title </p>
							<input
								className="form-control"
								required
								value={sliderImagesLinkTitleEn}
								onChange={(e) =>
									sliderImagesLinkTitleEnSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Link URL </p>
							<input
								className="form-control"
								required
								value={sliderImagesLinkUrl}
								onChange={(e) =>
									sliderImagesLinkUrlSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> {t("image")} </p>
							<input
								className="form-control"
								style={{ height: "45px" }}
								required
								type="file"
								onChange={(e) =>
									sliderImagesFileSet(e.target.files[0])
								}
							/>
						</div>
						{errors["image"] && (
							<p className="text-danger">{errors["image"]}</p>
						)}
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>
						<button
							className="btn btn-success"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("add")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Edit Slider Image */}
			<Modal
				size="lg"
				show={editSliderImage}
				onHide={handleClose}
				aria-labelledby="add-branch-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleEditSlideImage}>
					<Modal.Header>
						<Modal.Title id="add-branch-modal">
							{t("editImage")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<p className="text-left"> العنوان </p>
							<input
								className="form-control"
								required
								value={sliderImagesTitleAr}
								onChange={(e) =>
									sliderImagesTitleArSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Title </p>
							<input
								className="form-control"
								required
								value={sliderImagesTitleEn}
								onChange={(e) =>
									sliderImagesTitleEnSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> الوصف </p>
							<input
								className="form-control"
								required
								value={sliderImagesDescriptionAr}
								onChange={(e) =>
									sliderImagesDescriptionArSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Description </p>
							<input
								className="form-control"
								required
								value={sliderImagesDescriptionEn}
								onChange={(e) =>
									sliderImagesDescriptionEnSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> النص الرابط </p>
							<input
								className="form-control"
								required
								value={sliderImagesLinkTitleAr}
								onChange={(e) =>
									sliderImagesLinkTitleArSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Link title </p>
							<input
								className="form-control"
								required
								value={sliderImagesLinkTitleEn}
								onChange={(e) =>
									sliderImagesLinkTitleEnSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Link URL </p>
							<input
								className="form-control"
								required
								value={sliderImagesLinkUrl}
								onChange={(e) =>
									sliderImagesLinkUrlSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> {t("image")} </p>
							<input
								className="form-control"
								style={{ height: "45px" }}
								type="file"
								onChange={(e) =>
									sliderImagesFileSet(e.target.files[0])
								}
							/>
						</div>
						{errors["image"] && (
							<p className="text-danger">{errors["image"]}</p>
						)}
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>
						<button
							className="btn btn-primary"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("edit")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Delete Slider Image */}

			<Modal
				size="lg"
				show={deleteSliderImage}
				onHide={handleClose}
				aria-labelledby="delete-color-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<Modal.Header>
					<Modal.Title id="delete-color-modal">
						{t("deleteImage")}{" "}
						{deleteSliderImage["title_" + i18n.language]}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="text-left">{t("deleteImageModal")}</p>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn" onClick={handleClose}>
						{t("cancel")}
					</button>
					<button
						className="btn btn-danger"
						onClick={handleDeleteSlideImage}
						disabled={isSubmitting}
					>
						{isSubmitting && (
							<span
								className="spinner-border spinner-border-sm mr-2"
								role="status"
							></span>
						)}
						{t("delete")}
					</button>
				</Modal.Footer>
			</Modal>

			{/** Add Company feature */}
			<Modal
				size="lg"
				show={addCompanyFeature}
				onHide={handleClose}
				aria-labelledby="add-branch-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleAddCompanyFeature}>
					<Modal.Header>
						<Modal.Title id="add-branch-modal">
							{t("addNewFeature")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<p className="text-left"> العنوان </p>
							<input
								className="form-control"
								required
								value={companyFeatureTitleAr}
								onChange={(e) =>
									companyFeatureTitleArSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Title </p>
							<input
								className="form-control"
								required
								value={companyFeatureTitleEn}
								onChange={(e) =>
									companyFeatureTitleEnSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> الوصف </p>
							<input
								className="form-control"
								required
								value={companyFeatureDescriptionAr}
								onChange={(e) =>
									companyFeatureDescriptionArSet(
										e.target.value
									)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Description </p>
							<input
								className="form-control"
								required
								value={companyFeatureDescriptionEn}
								onChange={(e) =>
									companyFeatureDescriptionEnSet(
										e.target.value
									)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> {t("image")} </p>
							<input
								className="form-control"
								style={{ height: "45px" }}
								required
								type="file"
								onChange={(e) =>
									companyFeatureFileSet(e.target.files[0])
								}
							/>
						</div>
						{errors["image"] && (
							<p className="text-danger">{errors["image"]}</p>
						)}
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>
						<button
							className="btn btn-success"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("add")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Edit Company feature */}
			<Modal
				size="lg"
				show={editCompanyFeature}
				onHide={handleClose}
				aria-labelledby="add-branch-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<form onSubmit={handleEditCompanyFeature}>
					<Modal.Header>
						<Modal.Title id="add-branch-modal">
							{t("editFeature")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<p className="text-left"> العنوان </p>
							<input
								className="form-control"
								required
								value={companyFeatureTitleAr}
								onChange={(e) =>
									companyFeatureTitleArSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Title </p>
							<input
								className="form-control"
								required
								value={companyFeatureTitleEn}
								onChange={(e) =>
									companyFeatureTitleEnSet(e.target.value)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> الوصف </p>
							<input
								className="form-control"
								required
								value={companyFeatureDescriptionAr}
								onChange={(e) =>
									companyFeatureDescriptionArSet(
										e.target.value
									)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> Description </p>
							<input
								className="form-control"
								required
								value={companyFeatureDescriptionEn}
								onChange={(e) =>
									companyFeatureDescriptionEnSet(
										e.target.value
									)
								}
							/>
						</div>

						<div className="form-group">
							<p className="text-left"> {t("image")} </p>
							<input
								className="form-control"
								style={{ height: "45px" }}
								type="file"
								onChange={(e) =>
									companyFeatureFileSet(e.target.files[0])
								}
							/>
						</div>
						{errors["image"] && (
							<p className="text-danger">{errors["image"]}</p>
						)}
					</Modal.Body>
					<Modal.Footer>
						<button className="btn" onClick={handleClose}>
							{t("cancel")}
						</button>
						<button
							className="btn btn-primary"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("edit")}
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			{/** Delete Company feature */}

			<Modal
				size="lg"
				show={deleteCompanyFeature}
				onHide={handleClose}
				aria-labelledby="delete-color-modal"
				className={i18n.language === "en" ? "" : "rtl"}
			>
				<Modal.Header>
					<Modal.Title id="delete-color-modal">
						{t("deleteFeature")}{" "}
						{deleteCompanyFeature["title_" + i18n.language]}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="text-left">{t("deleteFeatureModal")}</p>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn" onClick={handleClose}>
						{t("cancel")}
					</button>
					<button
						className="btn btn-danger"
						onClick={handleDeleteCompanyFeature}
						disabled={isSubmitting}
					>
						{isSubmitting && (
							<span
								className="spinner-border spinner-border-sm mr-2"
								role="status"
							></span>
						)}
						{t("delete")}
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default Settings;
